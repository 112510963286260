import React, {useEffect, useState} from 'react'
import Title from '../../components/Title'
import Pagination from '../../components/Pagination';
import ImageContainer from '../../components/ImageContainer';
import {db} from "../../firebase";
import { getDocs,orderBy, where, query, collection } from 'firebase/firestore'

export default function PhotoGallery() {
  useEffect(() => {
    fetchGalleryData();
  }, []);
    
  const [loading, setLoading] = useState(true);
  const [photoGalleryPosts, setPhotoGalleryPosts] = useState(null); 
  async function fetchGalleryData(){
    const pdfsRef = collection(db, "photogallery");
    const q = query(
                pdfsRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setPhotoGalleryPosts(posts);
    setLoading(false);
  }
  function getPhotoGalleryComArr(){
    let comArr = [];
    photoGalleryPosts.map((post, index)=> {
      return comArr.push(
        <ImageContainer
            key={index}
            data = {post.data}
            id = {post.id} admin={true}
          />
      )} 
    )
    return comArr;
  }
  return (
    <>
        <Title titleData={"Photo Gallery / 교회 사진"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
        </Title>
        <div className="max-w-5xl mx-auto items-center my-10 border-b border-slate-300 pb-10">
          {!loading && (
                <Pagination posts={getPhotoGalleryComArr()} numPostPerPage={9} key={photoGalleryPosts.id}/>
              )
          }
        </div>
    </>
  )
}
