import React, { useState } from 'react';
import  {useLocation, Link} from 'react-router-dom';
import {FaLocationDot} from 'react-icons/fa6'
import logo from '../asset/churchlogo.png'


export default function Header() {
  const location = useLocation();
  const [onInfoDrop, setOnInfoDrop] = useState(false);
  const [onNewsDrop, setOnNewsDrop] = useState(false);
  
  function isPathMatch(path){
    if(path === location.pathname) return true;
    if((path !== "/" && path !== "/video")  && path.test(location.pathname)) return true;
  }

  if(location.pathname === '/auth/login'
    || /^\/admin\/.*$/.test(location.pathname)
  ) return <></>;
  
  return (
      <div className='bg-white shadow-md z-40'>
        {location.pathname === '/' && (
            <div className="bg-[#395F89] hidden sm:flex justify-between items-center text-xs">
              <div className="flex items-center ">
                <FaLocationDot/>
                <p className="uppercase ml-1">Grace NewYork Korean Baptist Church
45-14 251 St #FL G, 11362</p>
              </div>
              <Link to={'/auth/login'}>
                <button className="p-1 cursor-pointer text-slate-500 shadow-sm
                                  hover:bg-blue-200 active:bg-blue-300 transition duration-150 ease-in-out"
                >Admin</button>
              </Link>
            </div>
          )
        }
        <Link to={"/"}>
          <div className="sm:hidden w-full">
            <img src={logo}
              alt="" 
              className="sm:hidden w-[150px] h-[45px] mx-auto"/>
          </div>
        </Link>
        <header className='flex justify-between items-center max-w-5xl mx-auto'>
          <Link to={"/"} className="hidden sm:inline">
            <div className="">
              <img src={logo}
                alt="" 
                className="lg:w-[340px] lg:h-[72px] w-[200px] h-[55px]"/>
            </div>
          </Link>
          <div className="relative inline-block w-full md:pl-[200px] lg:pl-[250px] px-6 sm:px-0">
            <ul className='flex justify-between sm:mx-10 text-sm lg:text-base
                            items-center text-center'>
                <Link to="/">
                  <li 
                    className={`cursor-pointer border-b-[3px] border-b-transparent
                              hover:border-b-[#395F89] hover:text-[#395F89] transition duration-150 ease-in-out
                              ${isPathMatch("/") && "!border-b-[#395F89]"} py-3 sm:py-4 lg:py-8`
                    }
                  >
                    홈
                  </li>
                </Link>
                <li 
                  className={`cursor-pointer border-b-[3px] border-b-transparent
                            hover:border-b-[#395F89] transition duration-150 ease-in-out
                            ${isPathMatch(/^\/info\/.*$/) && "!border-b-[#395F89]"} py-3 sm:py-4 lg:py-8
                  `}
                  onMouseOver={()=>{setOnInfoDrop(true);}}
                  onMouseEnter={()=>{setOnInfoDrop(true);}}
                  onMouseLeave={()=>{
                    setOnInfoDrop(false);
                  }}
                  onClick={()=>{setOnInfoDrop(!onInfoDrop)}}
                >
                  <span className = "hover:text-blue-500">우리교회는</span>
                  {onInfoDrop === false ? 
                    <div className="hidden"></div> : 
                    <div className="absolute z-10 top-[47px] sm:top-[55px] lg:top-[91px] w-[80px] md:w-[110px] text-center
                      origin-top-right bg-white shadow-md border-[2px] border-gray-300
                      animate-[dropmenu_0.25s_ease-in-out_1] p-[6px] md:p-2"
                    >
                    <ul className="text-xs lg:text-base flex-col flex-nowrap">
                      <Link to="/info/aboutus">
                        <li className='pb-1 md:pb-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                      transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                        '>
                            교회 소개
                        </li>
                      </Link>
                      <Link to="/info/vision">
                        <li className='py-1 md:py-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                      transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                        '>
                            목회 철학
                        </li>
                      </Link>
                      <Link to="/info/location-time">
                        <li className='py-1 md:py-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                      transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                        '>
                            교회 위치
                        </li>
                      </Link>
                    </ul>  
                    </div>
                  }
                </li>
                <Link to={"/video"}>
                  <li 
                    className={`cursor-pointer border-b-[3px] border-b-transparent
                              hover:border-b-[#395F89] hover:text-[#395F89] transition duration-150 ease-in-out
                              ${isPathMatch("/video") && "!border-b-[#395F89]"} py-3 sm:py-4 lg:py-8
                  `}>
                    동영상 예배
                  </li>
                </Link>
                <li 
                  className={`cursor-pointer border-b-[3px] border-b-transparent
                            hover:border-b-[#395F89] transition duration-150 ease-in-out
                            ${isPathMatch(/^\/news\/.*$/) && "!border-b-[#395F89]"} py-3 sm:py-4 lg:py-8
                  `} 
                  onMouseOver={()=>{
                    setOnNewsDrop(true);
                  }}
                  onMouseLeave={()=>{
                    setOnNewsDrop(false);
                  }}
                  onMouseEnter={()=>{
                    setOnNewsDrop(true);
                  }}
                  onClick={()=>{setOnNewsDrop(!onNewsDrop)}}
                > <span className = "hover:text-[#395F89]">교회소식</span>
                  {onNewsDrop === false ? 
                      <div className="hidden"></div> : 
                      <div className="absolute z-10 top-[47px] sm:top-[55px] lg:top-[91px] w-[80px] md:w-[90px] lg:w-[110px] text-center
                        origin-top-right bg-white shadow-md border-2 border-gray-300
                        animate-[dropmenu_0.25s_ease-in-out_1] p-[6px] right-0 md:p-2"
                      >
                      <ul className="text-xs lg:text-base flex-col flex-wrap">
                        <Link to="/news/announcement">
                          <li className='pb-1 md:pb-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                        transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                          '>
                              공지 및 안내
                          </li>
                        </Link>
                        <Link to="/news/weeklynews">
                          <li className='py-1 md:py-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                        transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                          '>
                              교회 주보
                          </li>
                        </Link>
                        <Link to="/news/photogallery">
                          <li className='py-1 md:py-3 border-b border-solid border-slate-300 hover:scale-105 active:scale-110
                                        transition ease-in-out duration-150 hover:text-[#395F89] hover:border-[#395F89]
                          '>
                              교회 행사
                          </li>
                        </Link>
                      </ul>  
                    </div>
                    } 
                </li>
            </ul>
          </div>
        </header>
      </div>
    
  )
}
