import { useState, useEffect } from 'react'
import {getStorage, ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {v4 as uuidv4} from "uuid";
import { collection, getDocs, query, serverTimestamp, where ,orderBy, doc, runTransaction} from "firebase/firestore";
import {db} from "../../firebase";
import SliderImg from '../../components/admin/SliderImg';
import { toast } from 'react-toastify';
import Sidenav from '../../components/admin/Sidenav';
import { Link } from 'react-router-dom';

export default function AdminHome() {
  // const auth = getAuth();
  const [progressBar, setProgressBar] = useState(0);
  const [homeAdminData, setHomeAdminData] = useState({
    mainSentence : "",
    origination : "",
    imgUrls: {}
  });
  const [cursorIndTextarea, setCursorIndTextarea] = useState(0)
  // form data 
  const [formData, setFormData] = useState({
    mainSentence : "",
    origination : "",
    images:{},
  });
  
  const [loading, setLoading] = useState(true);
  const [isImgUpload, setIsImgUpload] = useState(false);
  const [isStUpload, setIsStUpload] = useState(false);

  const [imageUpload, setImageUpload] = useState(false);
  const [sentenceUpload, setSentenceUpload] = useState(false);
  
  async function fetchAdminHomeData(){
    setLoading(true);
    const adminHomeRef = collection(db, "adminhome")
    const q = query(adminHomeRef,where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"), orderBy("timestamp", "desc"));
    const querySnap = await getDocs(q);
    const data = querySnap.docs[0].data();
    setHomeAdminData(data)
    setFormData((prevState => ({
      ...prevState,
      mainSentence: data.mainSentence,
      origination: data.origination
    })))
    setLoading(false);
  }

  useEffect(() => {
    fetchAdminHomeData();
  }, []);

  async function onSubmit(e){
    e.preventDefault();
    //store image function
    async function storeImages(image){
      return new Promise((resolve, reject) =>{
        const storage = getStorage();
        const filename = `${image.name}-${uuidv4()}`;
        const storageRef= ref(storage, filename);
        
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          'state_changed',
          (snapshot) =>{
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress + "%");
            setProgressBar(progress);
            switch(snapshot.state){
              case 'paused': 
                console.log("Paused");
                break;
              case 'running':
                console.log("running");
                break;
            }
          },
          (error) => {
            reject(error);
          },
          ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>{
              resolve(downloadURL);
            });
          }
        );
      });
    }
    let imgUrls = homeAdminData.imgUrls;
    if(isImgUpload){
      toast.info("이미지 업로드 중입니다. 화면을 닫지 말아주세요.");
      let getImgUrls = await Promise.all(
        [...formData.images].map((image) => storeImages(image))
      ).catch((err)=>{
        toast.error(err);
        return;
      });
      setProgressBar(101);
      getImgUrls.forEach((url)=>{
        imgUrls.push(url);
      })
      // console.log(getImgUrls);
      const docRef = doc(db, "adminhome", "wtIslgrcKvGysfeFllzZ");
      try {
        await runTransaction(db, async (transaction) =>{
          const homeDoc = await transaction.get(docRef);
          if(!homeDoc.exists()){
            throw Error("Document does not exist");
          }
          transaction.update(docRef, {
            imgUrls: imgUrls,
            timestamp : serverTimestamp(),
          });
        })
        setIsImgUpload(false);
        setImageUpload(false);
        toast.success("이미지 업로드가 완료되었습니다.");
      } catch (error) {
        toast.error(error  + " 관리자에게 문의해주세요.");
      }
    }
    if(isStUpload){
      const docRef = doc(db, "adminhome", "wtIslgrcKvGysfeFllzZ");
      try {
        await runTransaction(db, async (transaction) =>{
          const homeDoc = await transaction.get(docRef);
          if(!homeDoc.exists()){
            throw Error("Document does not exist");
          }
          transaction.update(docRef, {
            mainSentence: formData.mainSentence,
            origination: formData.origination,
            timestamp : serverTimestamp(),
          });
        })
        toast.success("말씀 업데이트가 완료되었습니다.");
        setIsStUpload(false);
        setSentenceUpload(false);
      } catch (error) {
        toast.error(error + " 관리자에게 문의해주세요.");
      }
    }
    fetchAdminHomeData();
    // location.window.reload();
  }

  function onChange(e){
     if(e.target.files){
       setIsImgUpload(true);
       setFormData((prevState => ({
         ...prevState,
         images:e.target.files
       })))
     }
     else{
       setIsStUpload(true);
       setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
       }))
     }
  }

  async function onDelete(id){
    if(!window.confirm("삭제하시겠습니까?")) return;
    //get img array
    let imgUrls = [];
    //find and remove the url
    for(let i=0;i<homeAdminData.imgUrls.length;i++){
      if(i === id) continue;
      imgUrls.push(homeAdminData.imgUrls[i])
    } 
    //update
    const docRef = doc(db, "adminhome", "wtIslgrcKvGysfeFllzZ");
    try {
      await runTransaction(db, async (transaction) =>{
        const homeDoc = await transaction.get(docRef);
        if(!homeDoc.exists()){
          throw Error("Document does not exist");
        }
        transaction.update(docRef, {
          imgUrls: imgUrls,
          timestamp : serverTimestamp(),
        });
        
      })
    } catch (error) {
      toast.error(error + " 관리자에게 문의해주세요.");
    }
    toast.success("이미지 삭제가 완료되었습니다.")
    fetchAdminHomeData();
  }

  function moveRight(id){
    console.log(id + " right");
    //0 1 2 3 4 
    let URLs = homeAdminData.imgUrls;
    if(id === URLs.length) return;
    let tempUrl = URLs[id+1];
    URLs[id+1] = URLs[id];
    URLs[id] = tempUrl;
    setHomeAdminData({imgUrls : URLs});
  }

  function moveLeft(id){
    console.log(id + " left");
    let URLs = homeAdminData.imgUrls;
    if(id === 0) return;
    let tempUrl = URLs[id-1];
    URLs[id-1] = URLs[id];
    URLs[id] = tempUrl;
    setHomeAdminData({imgUrls : URLs});
  }

  function addBrTag(){
    let copySentenceFront = formData.mainSentence.substring(0, cursorIndTextarea);
    let copySentenceBack = formData.mainSentence.substring(cursorIndTextarea);
    copySentenceFront = copySentenceFront.concat("<br>");
    setFormData((prevState => ({
      ...prevState,
       mainSentence:copySentenceFront.concat(copySentenceBack),
    })));
  }

  async function saveOrder(){
    if(!window.confirm("슬라이더 순서를 저장하시겠습니까?")) return;

    const docRef = doc(db, "adminhome", "wtIslgrcKvGysfeFllzZ");
    try {
      await runTransaction(db, async (transaction) =>{
        const homeDoc = await transaction.get(docRef);
        if(!homeDoc.exists()){
          throw Error("Document does not exist");
        }
        transaction.update(docRef, {
          imgUrls: homeAdminData.imgUrls,
          timestamp : serverTimestamp(),
        });
        
      })
    } catch (error) {
      toast.error(error + " 관리자에게 문의해주세요.");
    }
    toast.success("순서 저장이 완료되었습니다");
    fetchAdminHomeData();
  }

  if(loading) return (<></>);

  if(progressBar > 0 && progressBar !== 101) {
    return (
        <div className="w-full h-screen bg-slate-100">
          <div className="h-screen flex justify-center items-center">
          {/* container */}
            <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                            flex-col justify-center items-center w-[50%] p-2 rounded-sm
            ">
                <h2 className='font-bold text-lg'>이미지를 업데이트 중입니다...</h2>
                <div style={{width : `${parseInt(progressBar)}%` }} 
                      className="bg-sky-500 p-[6px] text-xs mt-2 rounded-sm border text-right">{parseInt(progressBar) < 100 ? parseInt(progressBar).toString() : "완료! 잠시만 기다려주세요."}</div>
            </div>
          </div>
        </div>
    )
  }

  return (
    <>
      <div className="w-full bg-blue-100 text-center py-3 shadow-md text-xl font-semibold">
        <div className="flex max-w-5xl mx-auto items-center justify-between">
          <Link to={"/"}>
            <div className="">
              <img src="https://firebasestorage.googleapis.com/v0/b/gnkbc-web.appspot.com/o/churchlogo.png?alt=media&token=c6fc3e47-0c39-498f-8e52-3e3c9970843e" 
                alt="" 
                className="w-[200px] h-[55px]"/>
            </div>
          </Link>
          <span className="">
            관리자 페이지 - Main
          </span>
        </div>
      </div>
      <div className="flex bg-blue-100">
        <Sidenav></Sidenav>
        <div className="bg-white max-w-5xl mx-auto">
          {/* main slider imgs input container */}
          <div className="grid grid-cols-4">
            {/* layout, read and update with DB, by the order */}
            <h3 className="text-center col-span-4 border-t border-b shadow-md border-solid 
                          border-slate-200 py-5 mb-3 text-2xl relative bg-white flex justify-center items-center
            ">
              메인 이미지
              <button className="ml-3 px-[10px] pb-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                      transition duration-150 ease-in-out shadow-sm"
                      onClick={()=>{setImageUpload(!imageUpload)}}
              >
                      +
              </button>
              <button className="ml-3 mt-[2px] px-[10px] py-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                      transition duration-150 ease-in-out shadow-sm text-base"
                      onClick={()=>{saveOrder()}}
              >
                      순서 저장
              </button>
            </h3>
            {imageUpload && 
              <div className="col-span-4 items-center">
                <div className="relative w-[50%] mx-auto bg-slate-300 animate-dropmenu z-99 p-5">
                  {/* <span className="absolute right-7 top-[20px] bg-red-400 px-2 rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600" onClick={()=>setImageUpload(false)}>X</span> */}
                  <div className="">
                    <div className="flex justify-between border-t-[1px] border-b-[1px] border-slate-50 py-2 shadow-sm">
                      <h3 className="text-xl font-semibold">슬라이더 사진 업로드</h3>
                      <span className="bg-red-400 px-2 pt-[1.5px] rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600" 
                            onClick={()=>setImageUpload(false)}>X</span>
                    </div>
                    
                    <form onSubmit={onSubmit} className="flex flex-col">
                      <p className="my-3">업로드 된 사진들은 뒤의 순서로 추가 됩니다.</p>
                      <input type="file" id='images' onChange={onChange} accept='.jpg,.png,.jpeg'
                        multiple required className="bg-white bg-opacity-70 border border-gray-50 rounded text-slate-600"
                      />
                      <button type="submit" className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-1 mt-3 rounded-sm transition duration-150 ease-in-out">추가하기</button>
                    </form>
                  </div>
                </div>
              </div>
            }
            {/* image editing section */}
            <div className="col-span-4 flex flex-wrap">
              {/* image container */}
              {!loading && 
                (<>{
                    homeAdminData.imgUrls.map((url, index) => 
                        (<SliderImg imgURL={url} key={index} 
                            onDelete={()=>onDelete(index)}
                            moveLeft={()=>moveLeft(index)}
                            moveRight={()=>moveRight(index)}
                        />))
                }</>)
              }
            </div>
            {/* section end */}
            {/* sentence section start */}
            <h3 className="text-center col-span-4 border-t border-b shadow-md border-solid border-slate-200 py-5 my-10 text-2xl
            ">메인 말씀</h3>
            <div className="col-span-4 text-center mb-10">
              <div className="w-[50%] flex-col mx-auto items-center">
                <div className="font-semibold text-lg" dangerouslySetInnerHTML={{__html:homeAdminData.mainSentence}}></div>
                {/* <p className="font-semibold text-lg mb-4">
                  {homeAdminData.mainSentence}
                </p> */}
                <span className="font-bold text-lg">{homeAdminData.origination}</span>
                <div className="flex justify-evenly space-x-4 my-10">
                  <button className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-2 rounded-sm transition duration-150 ease-in-out"
                          onClick={()=>{setSentenceUpload(!sentenceUpload)}}
                  >
                    편집하기
                  </button>
                </div>
                {sentenceUpload && 
                  (<div className="relative bg-slate-300 animate-dropmenu my-5 p-5">
                    <span className="absolute right-7 top-[31px] bg-red-400 px-2 rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600"
                          onClick={()=>setSentenceUpload(false)}>X</span>
                    <div className="">
                      <h3 className="text-xl font-semibold shadow-sm border-t-[1px] border-b-[1px] border-slate-50 py-2">말씀 수정하기</h3>
                      <form className="flex-col space-y-2 mt-2" onSubmit={onSubmit}>
                        <p className="mt-3 flex justify-between items-center">말씀을 입력해주세요. 
                              <span className="bg-blue-400 rounded-md p-1 text-sm hover:bg-blue-500 transition-colors duration-150 ease-in-out
                                                cursor-pointer active:bg-blue-600" 
                                    onClick={() => addBrTag()}
                              >
                                    줄내림
                              </span>
                        </p>
                        <textarea name="" id="mainSentence" onChange={onChange} cols="30" rows="5" value={formData.mainSentence}
                                  className="w-full p-[6px]" 
                                  onMouseUp={e => {
                                      setCursorIndTextarea(e.target.selectionStart);
                                    }
                                  }
                        ></textarea>
                        <div className="text-left">
                          <p className="mb-2">출처를 입력해주세요.</p>
                          <input className="p-1 w-full bg-slate-50 mb-3" type="text" name="" id="origination" onChange={onChange}
                                value = {formData.origination}
                          />
                        </div>
                        
                        <button type="submit" className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-2 rounded-sm transition duration-150 ease-in-out">저장하기</button>
                      </form>
                    </div>
                  </div>)
                }
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}
