import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router';
import Title from '../../components/Title';

export default function VideoWorshipDetailPage() {
    const API_KEY = "AIzaSyD7vO7GBKeT5xIaXuzAtRTrOfIB7iqEsAs"
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [videoData, setVideoData] = useState(null);
    useEffect(() => {
        const worshipURL = 
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&maxResults=25&id=${params.id}&key=${API_KEY}`
        fetch(worshipURL)
             .then((res) => res.json())
             .then((data) => {
                //  console.log(data)
                let lists = [];
                data.items.forEach((item) =>{
                  return lists.push({
                    videoTitle: item.snippet.title,
                    videoDesc : item.snippet.description,
                    time: item.snippet.publishedAt.slice(0,10)
                  })
                })
                // console.log(lists);
                setVideoData(lists[0]);
                setLoading(false);
             })
             .catch((err) => {
                console.log(err.message);
             });
      }, []);
  return (
    <>
        <Title titleData={"Video Worship / 온라인 예배"} brightPercent={60} pos={"50% 65%"}
              backImg="https://images.unsplash.com/photo-1536126750180-3c7d59643f99?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80">
        </Title>
        {!loading && (
            <div className="col-span-4 w-full max-w-5xl mx-auto">
                <div className="flex-col justify-center items-center text-center py-5 my-5">
                    <h3 className="font-bold md:text-lg lg:text-xl py-6 border-t-2 border-b-2 border-[#395F89] border-solid shadow-sm">{videoData.videoTitle}</h3>
                    <h3 className="font-semibold text-sm md:text-base lg:text-lg mt-4 mb-4 lg:mb-8">{videoData.time}</h3>
                    <iframe src={`https://www.youtube.com/embed/${params.id}`}
                    title={`${videoData.videoTitle}`}
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen className="w-full h-[50vh] md:h-[75vh] xl:h-[70vh] mb-2"
                    >
                    </iframe>
                    <p className="mt-5 bg-blue-50 text-sm md:text-base lg:text-lg">{videoData.videoDesc}</p>
                </div>
            </div>
        )}
    </>
  )
}
