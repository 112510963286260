import React from 'react'
import Slider from '../components/Slider'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import {db} from "../firebase";
import { getDocs,orderBy, where, query, collection, limit } from 'firebase/firestore'

export default function Home() {
  const API_KEY = "AIzaSyD7vO7GBKeT5xIaXuzAtRTrOfIB7iqEsAs";
  const pos = [40.76841, -73.73679];
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  const [annnouncements, setAnnouncements] = useState([]);
  const [photoGalleryPosts, setPhotoGalleryPosts] = useState([]); 
  const [homeData, setHomeData] = useState({
    mainSentence : "",
    origination : "",
    imgUrls: []
  });

  useEffect(() => {
    fetchHomeData();
    fetchAnnounceData();
    fetchGalleryData();
    const playListURL = 
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1&playlistId=PLtjZ5tUKoyInyaXlh0U6Os0ljPyQCFVjW&key=${API_KEY}`
    fetch(playListURL)
         .then((res) => res.json())
         .then((data) => {
            let lists = [];
            data.items.forEach((item) =>{
              return lists.push({
                videoID: item.snippet.resourceId.videoId,
                videoTitle: item.snippet.title,
                videoDesc : item.snippet.description,
                time: item.snippet.publishedAt.slice(0,10)
              })
            })
            console.log(lists);
            setVideoList(lists);
            setLoading(false);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }, []); 

  async function fetchHomeData(){
    setLoading(true);
    const adminHomeRef = collection(db, "adminhome")
    const q = query(adminHomeRef,where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"), orderBy("timestamp", "desc"));
    const querySnap = await getDocs(q);
    const data = querySnap.docs[0].data();
    // console.log(data);
    setHomeData((({
      mainSentence : data.mainSentence,
      origination : data.origination,
    })))
    let imgs = [];
    data.imgUrls.forEach((url)=>{
      return imgs.push(url);
    })
    setHomeData((prevState=>({
      ...prevState,
      imgUrls : imgs
    })));
  }

  async function fetchGalleryData(){
    const pdfsRef = collection(db, "photogallery");
    const q = query(
                pdfsRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc"),
                limit(6)
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setPhotoGalleryPosts(posts);
    console.log(posts);
    setLoading(false);
  }

  async function fetchAnnounceData(){
    const annoRef = collection(db, "announcement");
    const q = query(
                annoRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc"),
                limit(4)
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setAnnouncements(posts);
    console.log(posts);
  }

  return (
    <div className="">
      {!loading && 
            (<Slider imgURLs={homeData.imgUrls}></Slider>)
      }
      <div className="grid grid-cols-4 max-w-5xl my-5 mx-auto">
        <div className="col-span-4 mb-4 md:mb-0 md:mr-6 md:col-span-2">
          <div className="flex justify-between items-end px-1 lg:px-0">
            <h4 className='sm:text-lg md:text-xl xl:text-2xl font-medium'>온라인 예배</h4>
            <Link to={"/video"}>
              <span 
                className='text-gray-500 cursor-pointer hover:text-gray-700 
                active:text-black transition duration-150 ease-in-out text-xs md:text-base'
              >
                  보러가기
              </span>
            </Link>
          </div>
          {!loading && videoList.length > 0 && (<>
              <iframe src={`https://www.youtube.com/embed/${videoList[0].videoID}`}
              title={`${videoList[0].videoTitle}`}
              frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen className="w-full h-[350px] mt-2 md:mt-4"
              >
              </iframe>
            </>
          )}
        </div>
        <div className="col-span-4 md:col-span-2 px-1">
          <h4 className='font-medium border-b-2 border-slate-400 pb-2 md:pb-4 border-solid md:text-xl xl:text-2xl'>주일 예배</h4>
          <ul className="mt-1 px-1">
            <li className="my-2 pb-2 border-b-[1.5px] grid grid-cols-4 items-center">
              <span className="md:text-lg w-full col-span-2">1부 예배</span>
              <span className="text-slate-500 col-span-1 text-left">오전 9:00</span>
              <span className="text-slate-700 col-span-1 text-right">예배당</span>
            </li>
            <li className="my-2 pb-2 border-b-[1.5px] grid grid-cols-4 items-center">
              <span className="md:text-lg w-full col-span-2">2부 예배</span>
              <span className="text-slate-500 col-span-1 text-left">오전 11:00</span>
              <span className="text-slate-700 col-span-1 text-right">예배당</span>
            </li>
            <li className="my-2 pb-2 border-b-[1.5px] grid grid-cols-4 items-center">
              <span className="md:text-lg w-full col-span-2">유년부</span>
              <span className="text-slate-500 col-span-1 text-left">오전 11:00</span>
              <span className="text-slate-700 col-span-1 text-right">교육관1</span>
            </li>
            <li className="my-2 pb-2 border-b-[1.5px] grid grid-cols-4 items-center">
              <span className="md:text-lg w-full col-span-2">Youth</span>
              <span className="text-slate-500 col-span-1 text-left">오전 11:00</span>
              <span className="text-slate-700 col-span-1 text-right">교육관2</span>
            </li>
          </ul>
          <h4 className='font-medium border-b-2 border-slate-400 pb-4 mt-6 border-solid md:text-xl xl:text-2xl'>평일 예배 / 모임</h4>
          <ul className="px-1">
            <li className="my-2 pb-2 border-b-[1.5px] flex justify-between items-center">
              <span className="md:text-lg w-[85px]">수요 예배</span>
              <span className="text-slate-500">오후 7:30</span>
              <span className="text-slate-700">예배당</span>
            </li>
            <li className="my-2 pb-2 border-b-[1.5px] flex justify-between items-center">
              <span className="md:text-lg w-[90px]">금요 기도회</span>
              <span className="text-slate-500">마지막 금요일 저녁 8:00</span>
              <span className="text-slate-700">예배당</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid max-w-5xl mx-auto mt-5 mb-10">
        <div className="h-[320px] lg:h-[400px] w-full">
            <MapContainer className="z-0" center={pos} zoom={40} scrollWheelZoom={false} style={{height:"100%", width:"100%"}}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={pos}>
                <Popup>
                   그레이스 뉴욕 교회 
                   <br/>
                   Grace NewYork Korean Baptist Church
                   <br />
                   45-14 251 St #FL G, 11362 
                </Popup>
              </Marker>
            </MapContainer>
          </div>
      </div>
      <div className="relative">
        <div style={{background: "url(https://images.unsplash.com/photo-1499652848871-1527a310b13a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2748&q=80) 55% 30%, no-repeat", 
                     backgroundSize:"cover", filter:"brightness(48%)" 
                    }} 
                  className="relative w-full h-[140px] md:h-[200px] overflow-hidden flex flex-col justify-center items-center text-center">
        </div>
        <div className="absolute bottom-[35px] md:bottom-[55px] text-center w-full mx-auto">
          <p className='text-sm md:text-lg text-white mb-2' dangerouslySetInnerHTML={{__html:homeData.mainSentence}}></p> 
          <span className="pb-5 font-medium md:text-xl text-white" dangerouslySetInnerHTML={{__html:homeData.origination}}></span>
        </div>
      </div>
      <div className="pb-6">
        <div className="grid grid-cols-4 max-w-5xl mx-auto mt-5 md:mt-10 px-1 lg:px-0">
          <div className="col-span-4 md:col-span-2 md:mr-5 md:mt-8">
            <div className="flex justify-between items-center">
                <h2 className="font-medium md:text-xl xl:text-2xl">교회공지 및 안내</h2>
                <Link to={"/news/announcement"}>
                  <span className='text-gray-500 cursor-pointer hover:text-gray-700 active:text-black transition duration-150 ease-in-out text-xs md:text-base'>더보기</span>
                </Link>
            </div>
            <ul className="border-t-2 border-slate-400 border-solid mt-2 md:mt-4">
              {!loading && annnouncements.map((announcement) => {
                  return (
                    <Link to={`/news/announcement/${announcement.id}`}>
                      <li className="text-sm md:text-base py-[10px] md:py-[14px] px-1 border-b-[1.5px] flex justify-between items-center
                                      hover:bg-blue-200 active:bg-blue-300 transition ease-in-out duration-150 cursor-pointer"
                      >
                        <span className="font-medium">{announcement.data.title}</span>
                        <span className="text-slate-700 text-xs md:text-sm">{announcement.data.timestamp.toDate().toString().slice(4,15).replaceAll(" ", "-")}</span>
                      </li>
                    </Link>
                  )
                })
              }
            </ul>
          </div>
          <div className="col-span-4 mt-4 md:col-span-2 md:ml-4 md:mt-8">
            <div className="flex justify-between items-center md:ml-3">
                <h2 className="font-medium md:text-xl xl:text-2xl">교회 갤러리</h2>
                <Link to={"/news/photogallery"}>
                  <span className='text-gray-500 cursor-pointer hover:text-gray-700 active:text-black transition duration-150 ease-in-out text-xs md:text-base'>더보기</span>
                </Link>
            </div>
            <div className="flex flex-wrap justify-around lg:justify-between px-1 mt-2 md:mt-4 md:px-0 md:ml-4">
                {!loading && photoGalleryPosts.map((post)=> {
                  return (
                    <Link to={`/news/photogallery/${post.id}`}> 
                      <div className="hover:scale-125 border-[1px] shadow-sm mb-1 md:mb-2 transition duration-150 ease-in-out cursor-pointer">
                        <img className='w-[175px] h-[140px] sm:w-[185px] md:w-[110px] md:h-24 lg:w-[155px] lg:h-[102px]' src={post.data.titleImageURL} alt="" />
                      </div>
                    </Link>
                  )
                })}
                               
            </div>
          </div>
        </div>
      </div>
    </div>  
  )
}
