import React from 'react'

export default function Staff({name, role, email, url, id, onDelete}) {
  return (
    <div className="col-span-1">
        <div className="flex flex-wrap items-center space-x-5 p-5">
            <img className="h-[100px] w-[100px] rounded-full" src={url} alt="" />
            <div className="flex-col text-xl">
                {/* content header */}
                <div className="flex space-x-2 items-baseline">
                    <h5 className="">{name}</h5>
                    <small className="">{role}</small>
                </div>
                <small>{email}</small>
                <button onClick={()=>onDelete(id)} 
                        className="w-[45%] bg-red-400 hover:bg-red-500 active:bg-red-700 py-1 px-5 my-2 rounded-sm transition duration-150 ease-in-out">삭제</button>
            </div>
        </div>
    </div>
  )
}
