import React from 'react'

export default function Title({titleData, backImg, brightPercent, pos}) {
  return (
    <div className="">
      <div className='relative flex w-full h-[86px] sm:h-[220px] lg:h-[300px] justify-center items-center'
          style={{
              background: `url(${backImg}) ${pos}, no-repeat`, opacity:"90%", 
              backgroundSize:"100%", filter:`brightness(${brightPercent}%)`
            }} 
      >
      </div>
      <div className="absolute w-full mx-auto text-center top-[115px] sm:top-[140px] lg:top-[215px]">
        <p className='font-medium sm:font-semibold text-xl sm:text-3xl lg:text-5xl text-white'>{titleData}</p> 
      </div>
    </div>
    
  )
}
