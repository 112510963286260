import React, { useState, useEffect } from 'react';
import VideoWorshipList from '../../components/VideoWorshipList';
import Pagination from '../../components/Pagination';
import Title from '../../components/Title'

export default function Videopage() {

  const API_KEY = "AIzaSyD7vO7GBKeT5xIaXuzAtRTrOfIB7iqEsAs"
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  useEffect(() => {
    const playListURL = 
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=40&playlistId=PLtjZ5tUKoyInyaXlh0U6Os0ljPyQCFVjW&key=${API_KEY}`
    fetch(playListURL)
         .then((res) => res.json())
         .then((data) => {
            let lists = [];
            data.items.forEach((item) =>{
              return lists.push({
                videoID: item.snippet.resourceId.videoId,
                videoTitle: item.snippet.title,
                videoDesc : item.snippet.description,
                time: item.snippet.publishedAt.slice(0,10)
              })
            })
            setVideoList(lists);
            setLoading(false);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }, []);
  function getVideoComArr(){
    let comArr = [];
    videoList.map((video, index)=> {
      if(index === 0) return null;
      return comArr.push(
        <VideoWorshipList
              key={index} 
              data={video} 
              id={video.videoID}
        />
      )}
    )
    return comArr;
  }
  return (
    <>
        <Title titleData={"Video Worship / 온라인 예배"} brightPercent={60} pos={"50% 65%"}
              backImg="https://images.unsplash.com/photo-1536126750180-3c7d59643f99?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80">
        </Title>
        <div className="col-span-4 w-full max-w-5xl mx-auto">
            {/* first video container */}
            {!loading && (
              <div className="flex-col justify-center items-center text-center my-5">
                <h3 className="font-bold text-base md:text-lg lg:text-xl py-4 border-t-2 border-b-2 mb-5 border-solid border-[#395F89] shadow-sm">
                  <span className="px-2 lg:px-0">
                  {videoList[0].videoTitle}
                  </span>
                </h3>
                <div className="mb-2">
                  {videoList[0].time}
                </div>
                
                <iframe src={`https://www.youtube.com/embed/${videoList[0].videoID}`}
                  title={`${videoList[0].videoTitle}`}
                  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen className="w-full h-[50vh] md:h-[75vh] xl:h-[70vh] mb-2"
                >
                </iframe>
                <p className="text-sm md:text-base lg:text-lg">{videoList[0].videoDesc}</p>
              </div>
            )}
            
            <div className="flex-col justify-center items-center text-center pb-10">
              <h3 className="font-bold text-base md:text-lg lg:text-xl py-2 md:py-4 border-t-2 border-b-2 border-solid shadow-sm border-[#395F89]">다른 예배 동영상</h3>
              {!loading && videoList.length > 0 && (<>
                  <Pagination posts={getVideoComArr()} numPostPerPage={5} key={videoList.id}/>
                </>)
              }
            </div>
        </div>
      </>
  )
}
