import React, {useState} from 'react'
import {getAuth} from "firebase/auth";
import { useLocation, Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {CgMenuBoxed} from 'react-icons/cg'

export default function Sidenav() {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuPop, setMenuPop] = useState(false)

//    useEffect(()=>{
//     console.log(auth.currentUser);
//    })
   

  function logout(){
      auth.signOut();
      navigate("/");
  }

  function isPathMatch(path){
    if(path === location.pathname) return true;
  }    

  function isDisplay(){
      if(menuPop === true) return "";
      else return "-translate-x-full h-0"
  }
//   function changeBg(){
//       if(menuPop === true) return "bg-blue-100";
//       else return "";
//   }

  
  if(!(/^\/admin\/.*$/.test(location.pathname))) return <></>;

  return (
    <div className="z-10 absolute">
        <nav className={`transition-colors duration-150 ease-out`}>
            <CgMenuBoxed 
                className={`text-3xl hover:scale-110 hover:bg-blue-100 mt-5 active:bg-blue-200 rounded-md cursor-pointer
                            transition-all duration-150 w-[40px] h-[30px] mx-auto`}
                onClick={()=>setMenuPop(!menuPop)}/>
            <div className={`px-2 text-center mt-[23.5px] ${isDisplay()} bg-white
                            transition-all duration-[250ms] ease-in-out shadow-md`}
            >
                <ul className="text-lg">
                    <Link to="/admin/home">
                        <li 
                        className={`cursor-pointer border-b-[3px] border-b-transparent
                                    hover:scale-[1.1] transition-transform duration-100 ease-in-out
                                    hover:bg-blue-300 active:bg-blue-500
                                    ${isPathMatch("/admin/home") && "!border-b-red-400"} p-2`
                        }
                        >
                        홈
                        </li>
                    </Link>
                    <Link to="/admin/info">
                        <li 
                        className={`cursor-pointer border-b-[3px] border-b-transparent
                                    hover:scale-[1.1] transition duration-100 ease-in-out
                                    hover:bg-blue-300 active:bg-blue-500
                                    ${isPathMatch("/admin/info") && "!border-b-red-400"} p-2`
                        }
                        >
                        교회 소개
                        </li>
                    </Link>
                    <Link to="/admin/news">
                        <li 
                        className={`cursor-pointer border-b-[3px] border-b-transparent
                                    hover:scale-[1.1] transition-transform duration-100 ease-in-out
                                    hover:bg-blue-300 active:bg-blue-500
                                    ${isPathMatch("/admin/news") && "!border-b-red-400"} p-2`
                        }
                        >
                        교회 소식
                        </li>
                    </Link>
                    {/* <Link to="/admin/video" className=" disabled:bg-slate-400 opacity-50"> */}
                        <li 
                        className={`border-b-[3px] border-b-transparent opacity-40 cursor-pointer
                                    hover:scale-[1.1] transition-transform duration-100 ease-in-out
                                    hover:bg-slate-300 active:bg-slate-400
                                    ${isPathMatch("/admin/video") && "!border-b-red-400"} p-2`
                        }
                        >
                        동영상 예배
                        </li>
                    {/* </Link> */}
                    <li className="pb-3">
                        <div 
                            onClick={() => {logout()}}
                            className="cursor-pointer hover:bg-blue-300 active:bg-blue-500 hover:scale-105 transition duration-150 ease-in-out
                                        text-center shadow-sm p-2 text-base"
                        >Sign out
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
  )
}
