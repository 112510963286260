import { getDoc, doc } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'
import Title from '../../components/Title';


export default function AnnouncementContents() {
    const params = useParams();
    const [contents, setContents] = useState(null);
    const [loading, setLoading] = useState(true);
 
    useEffect(() => {
        // console.log(loggedIn);
        async function fetchContents(){
            const ref = doc(db, "announcement", params.id);
            const docSnap = await getDoc(ref);
            if(docSnap.exists()){
                setLoading(false);
                setContents(docSnap.data());
            }
            // console.log(docSnap.data());
        }
        fetchContents();
    }, [params.id])
    
  if(loading) return <></>;
  return (
      <>
        <Title titleData={"Announcements / 교회공지 & 안내"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
        </Title>
        <div className="max-w-5xl w-full my-10 border-b border-solid border-r border-l
                        mx-auto flex-col justify-center clear-both">
            <div className="text-center border-b-2 border-t shadow-sm border-[#395F89] py-2 md:py-6">
                <h1 className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-bold">{contents.title}</h1>
                <small className="text-xs lg:text-sm xl:text-base">{contents.timestamp.toDate().toString().slice(0,15)}</small>
            </div>
            <div className=" px-5 mt-10 mb-20">
                <ReactQuill value={contents.textContent} readOnly={true} theme={'bubble'}></ReactQuill>
            </div>
            <div className="clear-both"></div>
        </div>
        
      </>
    
  )
}
