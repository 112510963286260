import React, {useState, useEffect} from 'react'
import Title from '../../components/Title'
import {db} from "../../firebase";
import Pagination from '../../components/Pagination'
import AnnouncementComp from '../../components/AnnouncementComp';
import { getDocs,orderBy, where, query, collection } from 'firebase/firestore'

export default function Announcement() {
  const [loading, setLoading] = useState(true);
  const [announcements, setAnnouncements] = useState(null); 
  useEffect(() => {
    fetchAnnounceData();
  }, []);
  async function fetchAnnounceData(){
    const annoRef = collection(db, "announcement");
    const q = query(
                annoRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setAnnouncements(posts);
    setLoading(false);
  }
  //integrate announcements with container, and covert the data as array
  //in order to transmit the container to Pagination comp
  function getAnnouncementComArr(){
    let comArr = [];
    announcements.map((announcement)=>{
      return comArr.push(
        <AnnouncementComp 
                    key={announcement.id}
                    id = {announcement.id}
                    data={announcement.data} 
            />
      )
    })
    return comArr;
  }

  return (
    <div className="mb-[110px] sm:mb-0">
      <Title titleData={"Announcements / 교회공지 & 안내"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
      </Title>
      <div className="max-w-5xl mx-auto flex-col pb-10 justify-center items-center mt-7 md:mt-10">
        <h2 
          className="py-3 lg:py-5 grid grid-cols-6 border-t-2 border-b-2 border-[#395F89]
                       md:text-lg font-semibold"
        >
          <span className="col-span-2 mx-auto">제목</span>
          <span className="col-span-2"></span>
          <span className="col-span-2 mx-auto">날짜</span>
        </h2>
        {!loading && (
            <Pagination posts={getAnnouncementComArr()} numPostPerPage={5} key={announcements.id}></Pagination>
        )}    
      </div>
    </div>
  )
}
