import TextEditor from '../../components/admin/TextEditor'
import { useAuthStatus } from '../../hooks/useAuthStatus';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react'
import { db } from '../../firebase';
import { getDoc, doc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'

export default function DetailPageForAdmin() {
 const {loggedIn, checkingStatus} = useAuthStatus();
 const [contents, setContents] = useState(null);
 const [loading, setLoading] = useState(true);
 const [collectionName, setCollectionName] = useState("")
 const [progressBar, setProgressBar] = useState(0);
const [barTitle, setBarTitle] = useState("수정 요청 처리중...사진 용량에 따라 시간이 달라집니다."); //progress bar title

 const location = useLocation();
 const navigate = useNavigate();
 const params = useParams();

 function completeReq(){
  toast.success("게시물을 최신 상태로 저장했습니다.");
  navigate("/admin/news")
  // setTimeout(()=>{
  //   navigate("/admin/news")
  // }, 2000);
}
 useEffect(() => {
    // console.log(loggedIn);
    async function fetchContents(){
       let collection = "";
        if(location.pathname === `/admin/announcementdetail/${params.id}`){
          setCollectionName("announcement");
          collection = "announcement";
        }
        else{
          setCollectionName("photogallery");
          collection = "photogallery"
        }
        console.log(collectionName)
        const ref = doc(db, collection, params.id);
        const docSnap = await getDoc(ref);
        if(docSnap.exists()){
            setLoading(false);
            setContents(docSnap.data());
        }
        // console.log(docSnap.data());
    }
    fetchContents();
  }, [params.id])

  if(progressBar > 0 && progressBar !== 101) {
    return (
        <div className="w-full h-screen bg-slate-100">
          <div className="h-screen flex justify-center items-center">
          {/* container */}
            <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                            flex-col justify-center items-center w-[50%] p-2 rounded-sm
            ">
                <h2 className='font-bold text-lg'>{barTitle}</h2>
                <div style={{width : "100%" }} 
                      className="bg-sky-500 p-[6px] text-xs mt-2 rounded-sm border">
                        {parseInt(progressBar) <= 1 ? "업로드 요청 중입니다." : "게시물을 업로드중입니다. 잠시만 기다려주세요..."}
                </div>
            </div>
          </div>
        </div>
    )
  }

  return (
    <div>
      <div className="w-full bg-blue-100 text-center py-3 shadow-md text-xl font-semibold">
        <div className="flex max-w-5xl mx-auto items-center justify-between">
          <Link to={"/"}>
            <div className="">
              <img src="https://firebasestorage.googleapis.com/v0/b/gnkbc-web.appspot.com/o/churchlogo.png?alt=media&token=c6fc3e47-0c39-498f-8e52-3e3c9970843e" 
                alt="" 
                className="w-[200px] h-[55px]"/>
            </div>
          </Link>
          <span className="">
            {location.pathname === `/admin/announcementdetail/${params.id}` ? "공지 / 안내 " : "갤러리"}
          </span>
        </div>
      </div>
      {loggedIn && !loading &&
        (<div className="my-10 pb-5">
            <TextEditor textInput={contents.textContent} 
              contentTitle={contents.title} id={params.id} 
              collectionName={collectionName} completeReq={completeReq}
              bar={(num)=> setProgressBar(num)}>
            </TextEditor>
        </div>)
      }
    </div>
  )
}
