import React from 'react'
import  {useLocation} from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Footer() {
  const location = useLocation();
  if(location.pathname === '/auth/login' || /^\/admin\/.*$/.test(location.pathname)) return <></>;
  return (
    <div className='bg-[#395F89] w-full md:py-10 py-5 clear-both'>
      <div className="max-w-5xl items-center flex justify-between mx-auto pr-3 sm:pr-2">
        <Link to={"/"}>
          <div className="">
          <img src="https://firebasestorage.googleapis.com/v0/b/gnkbc-web.appspot.com/o/asset%2Flogo.png?alt=media&token=05e7a2e3-de0a-48ac-ac01-57c3933120a1&_gl=1*1tiiqi5*_ga*MTg4ODc3NDY2OC4xNjg2ODM1NTkw*_ga_CW55HF8NVT*MTY5NzU2NDAxMi44MS4xLjE2OTc1NjQzMzYuNDAuMC4w"
                alt="" 
                className="w-[125px] md:w-[220px] lg:w-[265px] md:h-[70px]"/>
          </div>
        </Link>
        <div className="items-center text-left text-[8px] md:text-sm text-white">
            <p>ⓒ그레이스뉴욕교회 <br></br>All Rights Reserved</p>
            <p className="text-[3px] md:text-[10px] text-slate-400">Developed by Chihoon Kim</p>
        </div>
      </div>
    </div>
  )
}
