import React, {useState, useEffect} from 'react'
import WeeklyNews from '../../components/WeeklyNews'
import Pagination from '../../components/Pagination';
import Title from '../../components/Title'
import {db} from "../../firebase";
import { getDocs,orderBy, where, query, collection } from 'firebase/firestore'

export default function BulletinPage() {

  const [loading, setLoading] = useState(true);
  const [weeklyNews, setWeeklyNews] = useState(null);
  useEffect(() => {
    fetchPdfData();
  }, []);

  async function fetchPdfData(){
    const pdfsRef = collection(db, "weeklynews");
    const q = query(
                pdfsRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let news = [];
    querySnap.forEach((doc) => {
      return news.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setWeeklyNews(news);
    setLoading(false);
  }

  function getNewsComArr(){
    let comArr = [];
    weeklyNews.map((news, index)=> {
      return comArr.push(
        <WeeklyNews 
              key={index} 
              data={news.data} 
              id={news.id} admin={false}
        />
      )}
    )
    return comArr;
  }
  
  return (
    <div className="mb-[110px] sm:mb-0">
        <Title titleData={"WeeklyNews / 교회 주보"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
        </Title>
        <div className="max-w-5xl mx-auto flex-col pb-10 justify-center items-center mt-7 md:mt-10">
          <h2 
            className="py-3 lg:py-5 border-t-2 border-b-2 border-[#395F89]
                        md:text-lg font-semibold text-center">
            <span className="">'주보 보기' 버튼을 클릭해주세요.</span>
          </h2>
            {!loading && (<>
              <Pagination posts={getNewsComArr()} numPostPerPage={5} key={weeklyNews.id}/>
            </>)
          }
        </div>
    </div>
  )
}
