// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8U5Y7R-1-thmg6CGEG58OmZJDGjcMFQY",
  authDomain: "gnkbc-web.firebaseapp.com",
  projectId: "gnkbc-web",
  storageBucket: "gnkbc-web.appspot.com",
  messagingSenderId: "382888465273",
  appId: "1:382888465273:web:bd53ffc3f325aa993615a8",
  measurementId: "G-B527HWW1RQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore();