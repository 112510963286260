import React , {useState, useEffect} from 'react'

export default function Pagination({posts, numPostPerPage}) {
    const [lastNumBtn, setLastNumBtn] = useState(0);
    const [btnIndex, setBtnIndex] = useState(1);
    const [btns, setBtns] = useState(null);

    useEffect(() => {
        setLastNumBtn(posts.length / numPostPerPage);
        populateBtn(posts.length); //init btns
    }, [posts])

    function populateBtn(length){
        let btnArr = [];
        for(let i=0;i<(length/numPostPerPage);i++){
            btnArr.push(
                <button 
                    key={i} 
                    className="text-sm lg:text-lg py-[2px] px-2 lg:py-[4px] lg:px-3  border-2 border-solid border-[#395F89]
                            hover:bg-blue-100 transition-all duration-150 ease-in-out active:bg-blue-400"    
                    onClick={()=>{setBtnIndex(i+1);}}
                >{i+1}</button>)
         }
         setBtns(btnArr);
    }
  return (
      <>
        <div className="flex flex-wrap w-full max-w-5xl">
            {posts.map((post, index)=>{
                    //mod starts with 0, so +1 is needed
                    if(Math.floor(index/numPostPerPage)+1 !== btnIndex) return null;  
                    return post;
                })}
        </div>
            
       <div className="flex space-x-2 justify-center items-center py-2 my-2 mt-4">
        {btnIndex-2 > 0 && 
            <button className="text-sm lg:text-lg py-[2px] px-2 lg:py-[4px] lg:px-3 border-2 border-solid border-[#395F89] hover:bg-blue-200 transition-all duration-150 ease-in-out"
                onClick={()=>{setBtnIndex(btnIndex-1);}}
            >&lt;</button>
        }
        {lastNumBtn > 0 && 
            btns.map((btn, index) => {
                if(index < btnIndex-3 || index > btnIndex+1) //showing range
                    return (<span key={index} className="text-3xl font-extrabold text-[#395F89] p-1 mb-4">.</span>);
                if(btnIndex === index+1){
                    btn = <button 
                                style={{background: "#395F89"}}
                                key={index} 
                                className="text-sm lg:text-lg py-[2px] px-2 lg:py-[4px] lg:px-3 shadow-sm border-2 border-solid border-[#395F89] 
                                        hover:bg-blue-200 transition-all duration-150 ease-in-out"    
                                onClick={()=>{setBtnIndex(index+1);}}
                            >{index+1}</button>
                }
                return btn;
            })
            

        }
        {btnIndex+1 < lastNumBtn && 
            <button className="text-sm lg:text-lg py-[2px] px-2 lg:py-[4px] lg:px-3 border-2 border-solid border-[#395F89]
                            hover:bg-blue-200 transition-all duration-150 ease-in-out"
                onClick={()=>{setBtnIndex(btnIndex+1);}}
            >&gt;</button>
        }
        </div>
        
      </>
  )
}
