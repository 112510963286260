import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import SwiperCore from "swiper";
import "swiper/css/bundle";
import "swiper/css/pagination";

export default function Slider({imgURLs}) {

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  return (
    <div style={
      {
        background: 'url(https://images.unsplash.com/photo-1530688957198-8570b1819eeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODh8fGNodXJjaHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60) center no-repeat'
        , backgroundSize:'cover'
      }
    }>
      <Swiper
        slidesPerView = {1} effect="fade" pagination={true} navigation = {true}
        modules={[Pagination, EffectFade, Autoplay]} autoplay = {{delay:3000}}
        className='max-w-5xl mx-auto'
      >
        {imgURLs.map((url, index) => {
          console.log(url)
          return(
            <SwiperSlide key={index} className='border-b border-slate-400'>
              <div style={{background: `url(${url}) center, no-repeat`, backgroundSize:"cover"}} 
                  className="relative w-full h-[320px] md:h-[420px] lg:h-[520px] overflow-hidden"
              >
              </div>
          </SwiperSlide>
        )})}
      </Swiper>
    </div>
  )
}
