import { getDoc, doc } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { db } from '../../firebase';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'
import Title from '../../components/Title';


export default function PhotoGalleryDetailPage() {
    const params = useParams();
    const [contents, setContents] = useState(null);
    const [loading, setLoading] = useState(true);
    const {loggedIn, checkingStatus} = useAuthStatus();
    const [progressBar, setProgressBar] = useState(0);

    async function fetchContents(){
        const ref = doc(db, "photogallery", params.id);
        const docSnap = await getDoc(ref);
        if(docSnap.exists()){
            setLoading(false);
            setContents(docSnap.data());
        }
        console.log(docSnap.data());
    }

    useEffect(() => {
        console.log(loggedIn);
        fetchContents();
    }, [params.id])

    function completeReq(){
        setProgressBar(0);
        window.location.reload();
    }

  if(loading) return <></>;

  if(progressBar > 0 && progressBar !== 101) {
    return (
        <div className="w-full h-screen bg-slate-100">
          <div className="h-screen flex justify-center items-center">
          {/* container */}
            <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                            flex-col justify-center items-center w-[50%] p-2 rounded-sm
            ">
                <h2 className='font-bold text-lg'>변경사항을 저장중입니다...</h2>
                <div style={{width : "100%" }} 
                      className="bg-sky-500 p-[6px] text-xs mt-2 rounded-sm border">
                        {parseInt(progressBar) <= 1 ? "업로드 요청 중입니다." : "게시물을 업로드중입니다. 잠시만 기다려주세요..."}
                </div>
            </div>
          </div>
        </div>
    )
  }

  return (
      <>
        <Title titleData={"Photo Gallery / 교회 사진"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
        </Title>
        <div className="max-w-5xl w-full my-10 border-b border-solid border-r border-l
                        mx-auto flex-col justify-center clear-both">
            <div className="text-center border-b-2 border-t shadow-sm border-[#395F89] py-2 md:py-6">
                <h1 className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-bold">{contents.title}</h1>
                <small className="text-xs lg:text-sm xl:text-base">{contents.timestamp.toDate().toString().slice(0,15)}</small>
            </div>
            <div className="px-5 mt-10 mb-20">
                <ReactQuill value={contents.textContent} readOnly={true} theme={'bubble'}></ReactQuill>
            </div>
            <div className="clear-both"></div>
        </div>
      </>
    
  )
}