import React, {useState, useEffect} from 'react'
import BibleSvg from '../../asset/svg/bible-svgrepo-com.svg'
import PraySvg from '../../asset/svg/hands-pray-svgrepo-com.svg'
import CircleSvg from '../../asset/svg/share-circle-svgrepo-com.svg'
import { Link } from 'react-router-dom'
import {v4 as uuidv4} from "uuid";
import { collection, getDocs, query, serverTimestamp, where , doc, runTransaction} from "firebase/firestore";
import {db} from "../../firebase";
import {getStorage, ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {getAuth} from "firebase/auth";
import Staff from '../../components/admin/Staff';
import Sidenav from '../../components/admin/Sidenav';
import {toast} from 'react-toastify';

export default function AdminInfo() {
  const auth = getAuth();
  const [pasImageUpload, setPasImageUpload] = useState(false);
  const [pasIntroUpload, setPasIntroUpload] = useState(false);
  const [staffInfoUpload, setStaffInfoUpload] = useState(false);
  const [cursorIndTextarea, setCursorIndTextarea] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [pageData, setPageData] = useState({
    mainImgUrl : {},
    intro: "",
    staffs: {},
  });
  const [formData, setFormData] = useState({
    mainImg : {},
    intro: "",
    staffs: {},
  });
  const mainImg = formData.mainImg;
  
  const [staffFormData, setStaffFormData] = useState({
    staffImage:{},
    staffName:"",
    staffRole:"",
    staffmail:"",
  })
  const staffImg = staffFormData.staffImage;

  const [loading, setLoading] = useState(true);

  async function fetchInfoData(){
    setLoading(true);
    const infoRef = collection(db, "info")
    const q = query(infoRef,where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"));
    const querySnap = await getDocs(q);
    const data = querySnap.docs[0].data();
    setPageData(data);
    setFormData((prevState => ({
      ...prevState,
      intro: data.intro
    })));
    setLoading(false);
  }

  useEffect(() => {
    fetchInfoData();
  }, []);
  
  async function storeImage(image){
    return new Promise((resolve, reject) =>{
      const storage = getStorage();
      const filename = `${image.name}-${uuidv4()}`;
      const storageRef= ref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        'state_changed',
        (snapshot) =>{
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress + "%");
          setProgressBar(progress);
          switch(snapshot.state){
            case 'paused': 
              console.log("Paused");
              break;
            case 'running':
              console.log("running");
              break;
          }
        },
        (error) => {
          reject(error);
        },
        ()=>{
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>{
            resolve(downloadURL);
          });
        }
      );
    });
  }

  async function onSubmitPastorIntro(e){
    e.preventDefault();
    if(pasIntroUpload){
      const docRef = doc(db, "info", "W5QpBPmeHt0ZwOLO5OsA");
      try {
        await runTransaction(db, async (transaction) =>{
          const infoDoc = await transaction.get(docRef);
          if(!infoDoc.exists()){
            throw Error("Document does not exist");
          }
          transaction.update(docRef, {
            intro: formData.intro,
            timestamp : serverTimestamp(),
          });
        })
      } catch (error) {
        toast.error(error);
      }
    }
    toast.success("인삿말 업데이트가 완료되었습니다.")
    setPasIntroUpload(false);
    fetchInfoData();
  }

  //process pastor's img and intro
  async function onSubmitPastorImg(e){
    e.preventDefault();
    // note
    // console.log(mainImg); //assigned but console.log(formData.mainImg[0]); - undefined
    if(pasImageUpload){
      const mainImgUrl = await Promise.all(
        [...mainImg].map((img) => storeImage(img))
      ).catch((err) => {
        toast.error(err + " 관리자에게 문의해주세요.");
        return;
      })
      const docRef = doc(db, "info", "W5QpBPmeHt0ZwOLO5OsA");
      try {
        await runTransaction(db, async (transaction) =>{
          const infoDoc = await transaction.get(docRef);
          if(!infoDoc.exists()){
            throw Error("Document does not exist");
          }
          transaction.update(docRef, {
            mainImgUrl: mainImgUrl,
            timestamp : serverTimestamp(),
          });
        })
      } catch (error) {
        toast.error(error + " 관리자에게 문의해주세요.")
      }
    }    
    setProgressBar(101);
    toast.success("사진 업로드가 완료되었습니다");
    setPasImageUpload(false);
    fetchInfoData();
  }

  async function onSubmitStaffInfo(e){
    e.preventDefault();

    const staffImgUrl = await Promise.all(
      [...staffImg].map((img) => storeImage(img))
    ).catch((err)=>{
      toast.error(err + " 관리자에게 문의해주세요.");
      return;
    })
    
    const staffFormDataCopy = {
      ...staffFormData,
      staffImgUrl,
      userRef: auth.currentUser.uid
    }
    delete staffFormDataCopy.staffImage;

    let staffArray = pageData.staffs;
    staffArray.push(staffFormDataCopy);

    const docRef = doc(db, "info", "W5QpBPmeHt0ZwOLO5OsA");
    try {
      await runTransaction(db, async (transaction) =>{
        const infoDoc = await transaction.get(docRef);
        if(!infoDoc.exists()){
          throw Error("Document does not exist");
        }
        transaction.update(docRef, {
          staffs: staffArray,
          timestamp : serverTimestamp(),
        });
      })
    } catch (error) {
      toast.error(error + " 관리자에게 문의해주세요.");
    }
    setProgressBar(101);
    toast.success(`${staffFormDataCopy.staffName}을 추가를 완료했습니다.`);
    setStaffInfoUpload(false);
    fetchInfoData();
  }

  function onChange(e){
    if(e.target.files && e.target.id !== "staffImage"){
      setFormData((prevState => ({
        ...prevState,
        mainImg:e.target.files
      })))
    }
    //process staff form data
    else if(e.target.id === "staffImage" || e.target.id === "staffName"
            || e.target.id === "staffEmail" || e.target.id === "staffRole"
    ){
      if(e.target.id === "staffImage"){
        setStaffFormData((prevState) => ({
          ...prevState,
          staffImage: e.target.files,
        }));
      }else{
        setStaffFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
        }))
      }
    }
    else{
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }))
    }
 }

 function addBrTag(){
  let copyIntroFront = formData.intro.substring(0, cursorIndTextarea);
  let copyIntroBack = formData.intro.substring(cursorIndTextarea);
  copyIntroFront = copyIntroFront.concat("<br>");
  setFormData((prevState => ({
    ...prevState,
     intro:copyIntroFront.concat(copyIntroBack),
  })));
}

 async function onStaffDelete(index){
    //TODO: update staff info
    console.log(index)
    if(!window.confirm(`${pageData.staffs[index].staffName}님을 삭제하시겠습니까?`)) return;
    let staffs = [];
    for(let i=0;i<pageData.staffs.length;i++){
      if(i === index) continue;
      staffs.push(pageData.staffs[i]);
    }

    const docRef = doc(db, "info", "W5QpBPmeHt0ZwOLO5OsA");
    try {
      await runTransaction(db, async (transaction) =>{
        const infoDoc = await transaction.get(docRef);
        if(!infoDoc.exists()){
          throw Error("Document does not exist");
        }
        transaction.update(docRef, {
          staffs: staffs,
          timestamp : serverTimestamp(),
        });
        
      })
    } catch (error) {
      toast.error(error + " 관리자에게 문의해주세요.");
    }
    toast.success(`${pageData.staffs[index].staffName}님을 삭제했습니다.`);
    fetchInfoData();
 }

 
 if(progressBar > 0 && progressBar !== 101) {
    return (
        <div className="w-full h-screen bg-slate-100">
          <div className="h-screen flex justify-center items-center">
          {/* container */}
            <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                            flex-col justify-center items-center w-[50%] p-2 rounded-sm
            ">
                <h2 className='font-bold text-lg'>이미지를 업데이트 중입니다...</h2>
                <div style={{width : `${parseInt(progressBar)}%` }} 
                      className="bg-sky-500 p-[6px] text-xs mt-2 rounded-sm border text-right">{parseInt(progressBar) < 100 ? parseInt(progressBar).toString() : "등록중입니다! 잠시만 기다려주세요."}</div>
            </div>
          </div>
        </div>
    )
  }
  return (
    <>
      <div className="w-full bg-blue-100 text-center py-3 shadow-md text-xl font-semibold">
        <div className="flex max-w-5xl mx-auto items-center justify-between">
          <Link to={"/"}>
            <div className="">
              <img src="https://firebasestorage.googleapis.com/v0/b/gnkbc-web.appspot.com/o/churchlogo.png?alt=media&token=c6fc3e47-0c39-498f-8e52-3e3c9970843e" 
                alt="" 
                className="w-[200px] h-[55px]"/>
            </div>
          </Link>
          <span className="">
            관리자 페이지 - About us / Info
          </span>
        </div>
      </div>
      <div className="flex bg-blue-100">
        <Sidenav></Sidenav>
        <div className="bg-white max-w-5xl mx-auto">
          <div className="grid grid-cols-5 relative">
            {/* section */}
            <h3 className="text-center col-span-5 border-t border-b shadow-md border-solid 
                          border-slate-200 py-5 mb-3 text-2xl
            ">이미지 & 인사말</h3>
            {/* image editing container */}
            <div className="bg-slate-200 p-4 m-5 col-span-2">
              <div className="text-center items-center">
                <h4 className="font-semibold text-xl mb-3">목사님 사진</h4>
                {!loading && (
                  <>
                    {
                      pageData.mainImgUrl.map((url, key) => (
                        <img key={key} src={url} className="w-[320px] h-[330px] mb-4 mx-auto"></img>
                      ))
                    }             
                  </>)
                }
                <div className="flex justify-evenly space-x-4 mb-4">
                  <button className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-[320px] py-2 rounded-sm transition duration-150 ease-in-out"
                          onClick={()=>{setPasImageUpload(!pasImageUpload)}}
                  >
                    변경
                  </button>
                </div>
                {pasImageUpload && (
                  <div className="col-span-4 items-center">
                  <div className="relative mx-auto bg-slate-300 animate-dropmenu z-99 p-5">
                    {/* <span className="absolute right-7 top-[20px] bg-red-400 px-2 rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600" onClick={()=>setImageUpload(false)}>X</span> */}
                    <div className="">
                      <div className="flex justify-between border-t-[1px] border-b-[1px] border-slate-50 py-2 mb-4 shadow-sm">
                        <h3 className="text-lg font-semibold">목사님 사진 업로드</h3>
                        <span className="text-sm bg-red-400 px-2 pt-[4px] rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600" 
                              onClick={()=>setPasImageUpload(false)}>X</span>
                      </div>
                      <form onSubmit={onSubmitPastorImg} className="flex flex-col">
                        <input type="file" id='images' onChange={onChange} accept='.jpg,.png,.jpeg'
                          multiple required className="bg-white bg-opacity-70 border border-gray-50 rounded text-slate-600"
                        />
                        <button type="submit" className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-1 mt-3 rounded-sm transition duration-150 ease-in-out">추가하기</button>
                      </form>
                    </div>
                  </div>
                </div>
                )
                  // <div className="col-span-4 items-center">
                  //   <div className="relative bg-slate-300 mt-5 mx-auto animate-dropmenu z-99 p-5">
                  //     <span className="absolute right-5 text-xl uppercase cursor-pointer" onClick={()=>setPasImageUpload(false)}>X</span>
                  //     <div className="opacity-100">
                  //       <h3 className="text-xl font-semibold">사진 업로드</h3>
                  //       <form onSubmit={onSubmitPastorImg}>
                  //         <p>사진을 추가해주세요.</p>
                  //         <input type="file" id='mainImage' onChange={onChange} accept='.jpg,.png,.jpeg'
                  //           required className="bg-white border border-gray-50 rounded"
                  //         />
                  //         <button type="submit">추가하기</button>
                  //       </form>
                  //     </div>
                  //   </div>
                  // </div>
                }
              </div>
            </div>
            
            {/* text editing container */}
            <div className="bg-slate-200 p-4 m-5 mr-5 col-span-3">
              <div className="flex justify-between items-center">
                <h4 className="font-semibold text-xl">인사말</h4>
                <button className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 py-1 px-5 rounded-sm transition duration-150 ease-in-out"
                        onClick={()=>{setPasIntroUpload(!pasIntroUpload)}}
                >
                  편집
                </button>
                
              </div>
              <div className="text-lg">
              {pasIntroUpload && 
                  (<div className="relative bg-slate-300 animate-dropmenu my-5 p-5">
                    <span className="absolute right-7 top-[31px] bg-red-400 px-2 rounded-md uppercase cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600"
                          onClick={()=>setPasIntroUpload(false)}>X</span>
                    <div className="">
                      <h3 className="text-xl font-semibold shadow-sm border-t-[1px] border-b-[1px] border-slate-50 py-2">인사말 수정하기</h3>
                      <form className="flex-col space-y-2 mt-2" onSubmit={onSubmitPastorIntro}>
                        <p className="mt-3 flex justify-between items-center">인사말을 입력해주세요. 
                              <span className="bg-blue-400 rounded-md p-1 text-sm hover:bg-blue-500 transition-colors duration-150 ease-in-out
                                                cursor-pointer active:bg-blue-600" 
                                    onClick={() => addBrTag()}
                              >
                                    줄내림
                              </span>
                        </p>
                        <textarea name="" id="intro" onChange={onChange} cols="30" rows="5" value={formData.intro}
                                  className="w-full p-[6px]" 
                                  onMouseUp={e => {
                                      setCursorIndTextarea(e.target.selectionStart);
                                    }
                                  }
                        ></textarea>
                        <button type="submit" className="bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-2 rounded-sm transition duration-150 ease-in-out">저장하기</button>
                      </form>
                    </div>
                  </div>)
                }
                <p className="my-4">
                  {!loading && (
                      <div className="font-semibold text-lg" dangerouslySetInnerHTML={{__html:pageData.intro}}></div>
                    )
                  }
                </p>
              </div>
            </div>
            {/* section end */}
            {/* staff section start */}
            <div className="col-span-5">
              <h3 className="text-center col-span-5 border-t border-b shadow-md border-solid border-slate-200 py-5 mb-8 text-2xl
                            items-center relative">
                  함께 섬기는 분들 사진, 연락처
                  <button className="ml-5 px-[10px] pb-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                      transition duration-150 ease-in-out shadow-sm absolute top-[17px]"
                          onClick={()=>{setStaffInfoUpload(!staffInfoUpload)}}
                  >
                      +
                  </button>
              </h3>
              <div className="grid grid-cols-2 max-w-6xl mx-auto">
                <div className="col-span-2">
                  {staffInfoUpload && (
                      <div className="relative bg-slate-300 animate-dropmenu my-5 p-5 w-[50%] mx-auto">
                        <span className="absolute right-7 top-[31px] bg-red-400 px-2 rounded-md uppercase text-base cursor-pointer hover:bg-red-500 transition-colors duration-150 ease-in-out active:bg-red-600"
                              onClick={()=>setStaffInfoUpload(false)}>X</span>
                        <div className="">
                          <h3 className="text-xl font-semibold shadow-sm border-t-[1px] border-b-[1px] border-slate-50 py-2">섬기는 분 추가하기</h3>
                          <form className="flex flex-col mt-3 mb-1" onSubmit={onSubmitStaffInfo}>
                            <label htmlFor="staffImage" className="text-lg font-semibold my-1">사진</label>
                            <input type="file" id='staffImage' onChange={onChange} accept='.jpg,.png,.jpeg'
                              required className="bg-white border border-gray-50 rounded]"
                            />
                            <div className="flex flex-col justify-between text-lg font-semibold">
                              <label htmlFor="staffName" className="my-1">이름</label>
                              <input type="text" id='staffName' onChange={onChange}
                                required className="bg-white border border-gray-50 rounded p-1"
                              />
                              <label htmlFor="staffRole" className="my-1">직책</label>
                              <input type="text" id='staffRole' onChange={onChange}
                                required className="bg-white border border-gray-50 rounded p-1"
                              />
                              <label htmlFor="staffEmail" className="my-1">이메일</label>
                              <input type="email" name="" id="staffEmail" onChange={onChange}
                                required className="bg-white border border-gray-50 rounded p-1"
                              />  
                            </div>
                            <button type="submit" className="mt-4 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 w-full py-2 rounded-sm transition duration-150 ease-in-out">저장하기</button>
                          </form>
                        </div>
                      </div>
                    )}
                </div>
                {!loading && 
                    (<>{
                      pageData.staffs.map((staff, index) => (
                        <Staff url={staff.staffImgUrl} 
                              name={staff.staffName}
                              email={staff.staffEmail}
                              role={staff.staffRole}
                              key={index} id={index}
                              onDelete={()=>onStaffDelete(index)}
                              />
                      ))
                    }</>)
                  }
              </div>
            </div>
            
            {/* vision section */}
            <h3 className="text-center col-span-5 border-t border-b shadow-md border-solid border-slate-200 py-5 mb-3 text-2xl
                            items-center mt-20">
                  교회 비전 (현재 편집기능 없음)
            </h3>
            <div className="col-span-5 opacity-20">
              <div className="col-span-5 mb-32">
                <div className="max-w-6xl mx-auto h-[100vh] mb-20">
                  <div className="flex justify-between mt-5 space-x-1">
                    <button className="w-full bg-blue-400 hover:bg-blue-500 active:bg-blue-700 py-1 px-5 rounded-sm transition duration-150 ease-in-out">헤더 타이틀 편집</button>
                    <button className="w-full bg-blue-400 hover:bg-blue-500 active:bg-blue-700 py-1 px-5 rounded-sm transition duration-150 ease-in-out">헤더 본문 편집</button>
                  </div>
                  {/* vision header */}
                  <div className="p-10 w-full bg-slate-300 mb-10 flex-col justify-center items-center text-center">
                      <p className="text-3xl font-bold text-yellow-800 mb-8">예수 그리스도가 주인인 공동체를 <br/> 이루는 것이 온누리교회의 목회철학입니다</p>
                      <p className='border-t-[2px] border-solid border-stone-500 w-24 mt-4 rounded-full mx-auto'></p>
                      <p className="mt-8 text-semibold text-lg">성경적인 공동체는 바로 예수 공동체입니다. <br /> 예수 그리스도가 주인이 되신 공동체요, 예수 그리스도를 위해 존재하는 공동체입니다. <br />
                        온누리교회는 예배 공동체, 성령 공동체, 선교 공동체를 이루어 <br /> 이 시대의 참된 예수 공동체로 존재하기를 추구하고 있습니다.</p>
                  </div>
                  {/* end */}
                  {/* main container */}
                  <div className="space-y-5">
                  {/* first vision container */}
                    <div className="grid grid-cols-6 items-center space-x-6">
                      <div className="col-span-6 flex justify-evenly space-x-1">
                        <button className="w-full mt-5 h-8 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out">비전1 편집</button>
                        <button className="w-full mt-5 h-8 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out">비전2 편집</button>
                        <button className="w-full mt-5 h-8 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out">비전3 편집</button>
                      </div>
                      {/* left */}
                      <div className="col-span-1">
                        <div className="flex-col text-center">
                          <img className="w-[70px] h-[125px] mx-auto" src={BibleSvg} alt="" />
                          <h4 className="text-bold text-xl">예배 공동체</h4>
                        </div>
                      </div>
                      {/* right */}
                      <div className="col-span-5 flex items-center flex-wrap">
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                          Voluptate mollitia molestias neque quas magni ipsa aspernatur quasi qui dolor, 
                          pariatur, maxime dolorum debitis in, veritatis itaque hic blanditiis magnam commodi.
                        </p>
                      </div>
                    </div>
                    {/* end */}
                    {/* second vision container */}
                    <div className="grid grid-cols-6 items-center space-x-6">
                      {/* left */}
                      <div className="col-span-1">
                        <div className="flex-col text-center">
                          <img className="w-[70px] h-[125px] mx-auto text-black" src={CircleSvg} alt="" />
                          <h4 className="text-bold text-xl">예배 공동체</h4>
                        </div>
                      </div>
                      {/* right */}
                      <div className="col-span-5 flex items-center flex-wrap">
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                          Voluptate mollitia molestias neque quas magni ipsa aspernatur quasi qui dolor, 
                          pariatur, maxime dolorum debitis in, veritatis itaque hic blanditiis magnam commodi.
                        </p>
                      </div>
                    </div>
                    {/* end */}
                    {/* third vision container */}
                    <div className="grid grid-cols-6 items-center space-x-6">
                      {/* left */}
                      <div className="col-span-1">
                        <div className="flex-col text-center">
                          <img className="w-[70px] h-[125px] mx-auto" src={PraySvg} alt="" />
                          <h4 className="text-bold text-xl">예배 공동체</h4>
                        </div>
                      </div>
                      {/* right */}
                      <div className="col-span-5 flex items-center flex-wrap">
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                          Voluptate mollitia molestias neque quas magni ipsa aspernatur quasi qui dolor, 
                          pariatur, maxime dolorum debitis in, veritatis itaque hic blanditiis magnam commodi.
                        </p>
                      </div>
                    </div>
                    {/* third end */}
                  </div>
                {/* main vision container end */}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>  
  )
}
