import React from 'react'
import { useState, useEffect } from 'react';
import VideoWorshipList from '../../components/VideoWorshipList';
import Pagination from '../../components/Pagination';
import Sidenav from '../../components/admin/Sidenav';

export default function AdminVideo() {
  const API_KEY = "AIzaSyD7vO7GBKeT5xIaXuzAtRTrOfIB7iqEsAs"
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  
  //TODO: need a function to request loading more video
  useEffect(() => {
    const playListURL = 
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=25&playlistId=PLYyJCobshLZl4NV5Qg8EghuE6S0G77OnB&key=${API_KEY}`
    fetch(playListURL)
         .then((res) => res.json())
         .then((data) => {
            let lists = [];
            data.items.forEach((item) =>{
              return lists.push({
                videoID: item.snippet.resourceId.videoId,
                videoTitle: item.snippet.title,
                videoDesc : item.snippet.description,
                time: item.snippet.publishedAt.slice(0,10)
              })
            })
            console.log(lists);
            setVideoList(lists);
            setLoading(false);
         })
         .catch((err) => {
            console.log(err.message);
         });
  }, []);

  function getVideoComArr(){
    let comArr = [];
    videoList.map((list, index)=> {
      return comArr.push(
        <VideoWorshipList
              key={index} 
              data={list} 
              id={list.videoID}
        />
      )}
    )
    return comArr;
  }

  if(loading) return <></>; //need spinner
  return (
    <div className="flex bg-blue-100">
      <Sidenav></Sidenav>
      <div className="bg-white max-w-5xl mx-auto">
        <div className="grid grid-cols-4">
          <div className="col-span-4">
            <div className="flex justify-center text-center items-center border-t border-b shadow-md border-solid 
                            border-slate-200 py-5 mb-3 text-2xl">
              <h3 className="">메인 동영상</h3>
            </div>
          </div>
          
          <div className="col-span-4 w-full max-w-5xl mx-auto">
              {/* first video container */}
              <div className="flex-col justify-center items-center text-center py-5 my-5">
                <h3 className="font-bold text-2xl py-4 border-t-2 border-b-2 mb-10 border-solid shadow-sm">{videoList[0].videoTitle}</h3>
                <iframe src={`https://www.youtube.com/embed/${videoList[0].videoID}`}
                  title={`${videoList[0].videoTitle}`}
                  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen className="w-full h-[75vh]"
                >
                </iframe>
                <p className="">{videoList[0].videoDesc}</p>
              </div>
              <div className="flex-col justify-center items-center text-center pb-10 space-y-6">
                <h3 className="font-bold text-2xl py-4 border-t-2 border-b-2 mb-10 border-solid shadow-sm">다른 예배 동영상</h3>
                {!loading && videoList.length > 0 && (<>
                    <Pagination posts={getVideoComArr()} key={videoList.id}/>
                  </>)
                }
              </div>
            </div>
          </div>
      </div>  
    </div>
    
  )
}
