import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router';

export default function ImageContainer({data, id}) {

  const location = useLocation();

  function getLink(){
    if(location.pathname === '/admin/news') return `/admin/photogallerydetail/${id}`;
    return `/news/photogallery/${id}`;
  }

  return (
        <div className="border shadow-md mx-auto my-2">
          <Link to={getLink()}>
            <img src={data.titleImageURL} alt="img" 
                className="w-[360px] sm:w-[320px] h-[240px] border-b-[1px] hover:scale-125 transition duration-100"
            />
          </Link>
            <div className="p-2 lg:p-4 text-sm lg:text-lg">
                <h4 className="font-semibold">{data.title}</h4>
                <span className="text-slate-500">{data.timestamp.toDate().toString().slice(0,15)}</span>
            </div>
        </div>
  )
}
