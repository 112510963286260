import React , {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { getAuth } from 'firebase/auth'
import {db} from "../../firebase";
import {v4 as uuidv4} from "uuid";
import { toast } from 'react-toastify';
import { getDocs, addDoc,orderBy, where, query, collection, serverTimestamp } from 'firebase/firestore'
import {getStorage, ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";

import Pagination from '../../components/Pagination'
import AnnouncementComp from '../../components/AnnouncementComp';
import ImageContainer from '../../components/ImageContainer';
import Sidenav from '../../components/admin/Sidenav';
import WeeklyNews from '../../components/WeeklyNews'
import TextEditor from '../../components/admin/TextEditor'


export default function AdminNews() {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [progressBar, setProgressBar] = useState(0);
  const [barTitle, setBarTitle] = useState("업데이트 요청..."); //progress bar title
  
  const [onTextEditor, setOnTextEditor] = useState(false);
  const [onPdfInput, setOnPdfInput] = useState(false);
  const [onPhotoGalleryInput, setOnPhotoGalleryInput] = useState(false);

  const [pdfFormData, setPdfFormData] = useState({
    pdfTitle:"",
    pdfFile1: {},
    pdfFile2: {}
  });
  const [pdfFileName, setPdfFileName] = useState("");

  //for fetching posts
  const [weeklyNews, setWeeklyNews] = useState(null);
  const [announcements, setAnnouncements] = useState(null); 
  const [photoGalleryPosts, setPhotoGalleryPosts] = useState(null); 

  async function fetchAnnounceData(){
    const annoRef = collection(db, "announcement");
    const q = query(
                annoRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setAnnouncements(posts);
  }

  async function fetchPdfData(){
    const pdfsRef = collection(db, "weeklynews");
    const q = query(
                pdfsRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let news = [];
    querySnap.forEach((doc) => {
      return news.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setWeeklyNews(news);
    console.log(news);
  }
  async function fetchGalleryData(){
    const pdfsRef = collection(db, "photogallery");
    const q = query(
                pdfsRef, 
                where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"),
                orderBy("timestamp", "desc")
              )
    const querySnap = await getDocs(q);
    let posts = [];
    querySnap.forEach((doc) => {
      return posts.push({
        id: doc.id,
        data: doc.data()
      })
    });
    setPhotoGalleryPosts(posts);
    setLoading(false);
  }

  useEffect(() => {
    fetchAnnounceData();
    fetchPdfData();
    fetchGalleryData();
    
  }, []);

  function onChange(e){
    if(e.target.id === "pdfFile1"){
      const filename = e.target.files[0].name;
      setPdfFileName(filename);
      setPdfFormData((prevState => ({
        ...prevState,
        pdfFile1: e.target.files,
      })));
    }
    else if(e.target.id === "pdfFile2"){
      const filename = e.target.files[0].name;
      setPdfFileName(filename);
      setPdfFormData((prevState => ({
        ...prevState,
        pdfFile2: e.target.files,
      })));
    }
    else if(e.target.id === "pdfTitle"){
      setPdfFormData((prevState => ({
        ...prevState,
        pdfTitle: e.target.value,
      })));
    }
  }
  
  async function onSubmit(e){
    e.preventDefault();
    console.log(pdfFormData.pdfFile1);
    if(pdfFileName.pdfTitle === ""){
      toast.error("제목을 입력해주세요.");
      return;
    }
    if(pdfFormData.pdfFile1 === {}){
      toast.error("전면 파일을 올려주세요.");
      return;
    }
    if(pdfFormData.pdfFile2 === {}){
      toast.error("후면 파일을 올려주세요");
      return;
    }
    async function storePdf(pdf){
      return new Promise((resolve, reject) =>{
        const storage = getStorage();
        const filename = `${pdf.name}-${uuidv4()}`;
        const storageRef= ref(storage, filename);
        
        const uploadTask = uploadBytesResumable(storageRef, pdf);
        uploadTask.on(
          'state_changed',
          (snapshot) =>{
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressBar(progress);
            switch(snapshot.state){
              case 'paused': 
                console.log("Paused");
                break;
              case 'running':
                console.log("running");
                break;
            }
          },
          (error) => {
            reject(error);
          },
          ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>{
              resolve(downloadURL);
            });
          }
        );
      });
    }
    let pdfFiles = [];
    pdfFiles.push(pdfFormData.pdfFile1[0]);
    pdfFiles.push(pdfFormData.pdfFile2[0]);
    toast.info("주보를 업로드 중입니다. 화면을 닫지 말아주세요.");
    
    let pdfUrl = await Promise.all(
      [...pdfFiles].map((pdf) => storePdf(pdf))
    ).catch((err) => {
      toast.error(err + " 관리자에게 문의해주세요.");
      return;
    });

    console.log(pdfFiles);

    setProgressBar(101);
    const pdfFormDataCopy = {
      ...pdfFormData,
      pdfUrl,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    }

    delete pdfFormDataCopy.pdfFile1;
    delete pdfFormDataCopy.pdfFile2;

    console.log(pdfFormDataCopy);

    await addDoc(collection(db, "weeklynews"), pdfFormDataCopy);
    setOnPdfInput(false);
    toast.success("업데이트가 완료되었습니다.");
    fetchPdfData();
  }

  function getAnnouncementComArr(){
    let comArr = [];
    announcements.map((announcement)=>{
      return comArr.push(
        <AnnouncementComp 
                    key={announcement.id}
                    id = {announcement.id}
                    data={announcement.data} 
            />
      )
    })
    return comArr;
  }

  function getNewsComArr(){
    let comArr = [];
    weeklyNews.map((news, index)=> {
      return comArr.push(
        <WeeklyNews 
              key={index} 
              data={news.data} 
              id={news.id} admin={true}
              onComplete = {completeNewsRemove}
        />
      )}
    )
    return comArr;
  }

  function getPhotoGalleryComArr(){
    let comArr = [];
    photoGalleryPosts.map((post, index)=> {
      return comArr.push(
        <div className="mx-2 my-2">
          <ImageContainer
            key={index}
            data = {post.data}
            id = {post.id} admin={true}
          />
        </div>
        
      )} 
    )
    return comArr;
  }

  function completeReq(){
    toast.success("게시물을 최신 상태로 저장했습니다.");
    setProgressBar(0);
    if(onTextEditor){
      setOnTextEditor(false);
      fetchAnnounceData();
    }
    else if(onPhotoGalleryInput){
      setOnPhotoGalleryInput(false);
      fetchGalleryData();
    }
  }

  function completeNewsRemove(){
    fetchPdfData();
  }

  if(progressBar > 0 && progressBar !== 101) {
    return (
        <div className="w-full h-screen bg-slate-100">
          <div className="h-screen flex justify-center items-center">
          {/* container */}
            <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                            flex-col justify-center items-center w-[50%] p-2 rounded-sm
            ">
                <h2 className='font-bold text-lg'>{barTitle}</h2>
                <div style={{width : "100%" }} 
                      className="bg-sky-500 p-[6px] text-xs mt-2 rounded-sm border">
                        {parseInt(progressBar) <= 1 ? "업로드 요청 중입니다." : "게시물을 업로드중입니다. 잠시만 기다려주세요..."}
                </div>
            </div>
          </div>
        </div>
    )
  }
  if(loading){
    return <></>;
  }
  return (
    <>
      <div className="w-full bg-blue-100 text-center py-3 shadow-md text-xl font-semibold">
        <div className="flex max-w-5xl mx-auto items-center justify-between">
          <Link to={"/"}>
            <div className="">
              <img src="https://firebasestorage.googleapis.com/v0/b/gnkbc-web.appspot.com/o/churchlogo.png?alt=media&token=c6fc3e47-0c39-498f-8e52-3e3c9970843e" 
                alt="" 
                className="w-[200px] h-[55px]"/>
            </div>
          </Link>
          <span className="">
            관리자 페이지 - News / Announcements
          </span>
        </div>
      </div>
      <div className="flex bg-blue-100">
        <Sidenav></Sidenav>
        <div className="bg-white max-w-5xl mx-auto">
          {/* main slider imgs input container */}
          <div className="grid grid-cols-4">
            <h3 className="text-center col-span-4 border-t border-b shadow-md border-solid 
                              border-slate-200 py-5 mb-3 text-2xl flex justify-center items-center
            ">교회 소식
              <button className="ml-5 px-[10px] pb-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                      transition duration-150 ease-in-out shadow-sm
                    "
                      onClick={()=>{setOnTextEditor(!onTextEditor); setBarTitle("교회 공지 업데이트 중입니다.")}}
              >
                      +
              </button>
            </h3>
            {onTextEditor && 
              <div className="col-span-4 w-full animate-[dropmenu_0.5s_ease-in-out_1]">
                <h4 className="bg-blue-50 w-full text-center py-3 text-lg font-semibold shadow-sm">교회소식 텍스트 편집기</h4>
                <TextEditor textInput={""} contentTitle={""} collectionName={"announcement"} 
                            completeReq={()=>{completeReq()}} bar={(num)=> setProgressBar(num)}/>
              </div>
            }
            <div className="col-span-4 w-full mx-auto py-10 flex flex-col items-center">
              {!loading && (<>
                  <Pagination posts={getAnnouncementComArr()} numPostPerPage={5} key={announcements.id}></Pagination>
                </>)
              }
            </div>
            {/* weekly news */}
            <h3 className="text-center col-span-4 border-t border-b shadow-md border-solid 
                          border-slate-200 py-5 mb-3 text-2xl relative
            ">주보
            <button className="ml-5 px-[10px] pb-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                    transition duration-150 ease-in-out shadow-sm absolute top-[17px]
                  " onClick={() => {setOnPdfInput(!onPdfInput); setBarTitle("교회 주보 업데이트 중입니다.");}}
            >
                    +
            </button>
            {onPdfInput && (
                <div className="mt-10 animate-[dropmenu_0.5s_ease-in-out_1] bg-blue-50">
                  <h4 className="w-full text-center py-3 text-lg font-semibold">주보 Input box</h4>
                  <form onSubmit={onSubmit}>
                      <div className="">
                          <label htmlFor="pdfTitle" className="text-lg">제목: </label>
                          <input type="text" id="pdfTitle" value={pdfFormData.pdfTitle} 
                                  className="bg-slate-200 p-1 px-2 rounded-sm text-base"
                                  onChange={(e)=>{onChange(e)}}        
                          />
                      </div>
                      <label htmlFor="pfdFile" className="text-lg">주보 이미지 앞/뒤 파일을 올려주세요. </label>
                      <div className="space-x-4">
                        <input type="file" id="pdfFile1"
                                className="bg-slate-200 p-1 px-2 rounded-sm text-base"
                                onChange={(e)=>{onChange(e)}}        
                        />
                        <input type="file" id="pdfFile2"
                                className="bg-slate-200 p-1 px-2 rounded-sm text-base"
                                onChange={(e)=>{onChange(e)}}        
                        />
                        <button type="submit"
                                className="text-base h-8 px-5 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out my-3">
                          올리기
                        </button>
                      </div>
                  </form>
                </div>
              )
            }
            </h3>
            <div className="col-span-4 w-full max-w-5xl mx-auto my-10 flex-col pb-10
                            justify-center items-center
            ">
              {!loading && (<>
                  <Pagination posts={getNewsComArr()} numPostPerPage={5} key={weeklyNews.id}/>
                </>)
              }
            </div>
            <div className="col-span-4">
              <h3 className="text-center col-span-4 border-t border-b shadow-md border-solid 
                            border-slate-200 py-5 mb-3 text-2xl relative
              ">갤러리
                <button className="ml-5 px-[10px] pb-1 bg-blue-400 rounded-full hover:bg-blue-500 active:bg-blue-700
                                        transition duration-150 ease-in-out shadow-sm absolute top-[17px]"
                        onClick={() => {setOnPhotoGalleryInput(!onPhotoGalleryInput); setBarTitle("갤러리 게시물을 업데이트 중입니다.");}}
                >
                        +
                </button>
              </h3>
              {onPhotoGalleryInput && (
                  <div className="animate-[dropmenu_0.5s_ease-in-out_1]">
                    <h4 className="bg-blue-50 w-full text-center py-3 text-lg font-semibold shadow-sm">갤러리 텍스트 편집기 </h4>
                    <h5 className="text-sm text-center w-full mx-auto mt-1">첫 번째 사진이 썸네일 사진으로 업데이트 됩니다.</h5>
                    <TextEditor textInput={""} contentTitle={""} collectionName={"photogallery"} 
                                completeReq={()=>{completeReq()}} bar={(num)=> setProgressBar(num)}
                    />
                  </div>
              )}
            </div>
            
            <div className="col-span-4 max-w-5xl mx-auto my-10 flex-col space-y-5 border-b border-slate-300 pb-10
                            items-center
            ">
              {!loading && (
                  <Pagination posts={getPhotoGalleryComArr()} numPostPerPage={5} key={photoGalleryPosts.id}/>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
