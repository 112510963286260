import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Home from "./pages/Home";
import VideoList from "./pages/news-pages/Videopage";
import Login from "./pages/Login";
import Aboutus from "./pages/info-pages/Aboutus";
import Vision from "./pages/info-pages/Vision";
import LocationTime from "./pages/info-pages/LocationTime";
import Announcement from "./pages/news-pages/Announcement";
import WeeklyNews from "./pages/news-pages/WeeklyNewsPage";
import PhotoGallery from "./pages/news-pages/PhotoGallery";
import AdminHome from "./pages/admin-pages/AdminHome";
import AdminNews from "./pages/admin-pages/AdminNews";
import AdminInfo from "./pages/admin-pages/AdminInfo";
import AdminVideo from "./pages/admin-pages/AdminVideo";
import DetailPageForAdmin from "./pages/admin-pages/DetailPageForAdmin";
import AdminRoute from "./components/admin/AdminRoute";

import Header from "./components/Header";
import Footer from "./components/Footer";
// import Sidenav from "./components/admin/Sidenav";
import AnnouncementDetailPage from "./pages/news-pages/AnnouncementDetailPage";
import PhotoGalleryDetailPage from "./pages/news-pages/PhotoGalleryDetailPage";
import VideoWorshipDetailPage from "./pages/news-pages/VideoWorshipDetailPage";

import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <>
    <Router>
      <Header/>
      {/* <Sidenav/> */}
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/info/aboutus" element={<Aboutus/>}></Route>
        <Route path="/info/location-time" element={<LocationTime/>}></Route>
        <Route path="/info/vision" element={<Vision/>}></Route>
        <Route path="/news/weeklynews" element={<WeeklyNews/>}></Route>
        <Route path="/news/announcement" element={<Announcement/>}></Route>
        <Route path="/news/announcement/:id" element={<AnnouncementDetailPage/>}></Route>
        <Route path="/news/photogallery" element={<PhotoGallery/>}></Route>
        <Route path="/news/photogallery/:id" element={<PhotoGalleryDetailPage/>}></Route>
        <Route path="/video" element={<VideoList/>}></Route>
        <Route path="/video/worship/:id" element={<VideoWorshipDetailPage/>}></Route>
        <Route path="/auth/login" element={<Login/>}></Route>
        <Route path="/admin/*" element={<AdminRoute/>}>
          <Route path="home" element={<AdminHome/>}></Route>
          <Route path="info" element={<AdminInfo/>}></Route>
          <Route path="news" element={<AdminNews/>}></Route>
          <Route path="video" element={<AdminVideo/>}></Route>
          <Route path="announcementdetail/:id" element={<DetailPageForAdmin/>}></Route>
          <Route path="photogallerydetail/:id" element={<DetailPageForAdmin/>}></Route>
        </Route>
      </Routes>
      <Footer/>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <Analytics />
    </>
  );
}



export default App;
