import React, { useState } from 'react'
import {db} from "../firebase";
import {v4 as uuidv4} from "uuid";
import {getStorage, ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {serverTimestamp, doc, runTransaction, deleteDoc} from "firebase/firestore";
import {toast} from "react-toastify";

export default function WeeklyNews({data, admin, id, onComplete}) {
  const [displayOn, setDisplayOn] = useState(false);
  const [onPdfEdit, setOnPdfEdit] = useState(false);
  const [pdfFormData ,setPdfFormData] = useState({
    pdfTitle:data.pdfTitle,
    pdfFile: {}
  })
  const [pdfUpload, setPdfUpload] = useState(false);

  async function onDelete(){
    if(window.confirm("이 게시물을 삭제하시겠습니까? 삭제시 복구가 불가능합니다.")){
      await deleteDoc(doc(db, "weeklynews", id));
      toast.success(data.pdfTitle + " 주보를 삭제했습니다.");
      onComplete();
    }
  }

  function onChange(e){
    console.log(e.target.value);
    if(e.target.id === "pdfFile"){
      setPdfFormData((prevState => ({
        ...prevState,
        pdfFile: e.target.files,
      })));
      setPdfUpload(true);
    }
    if(e.target.id === "pdfTitle"){
      setPdfFormData((prevState => ({
        ...prevState,
        pdfTitle: e.target.value,
      })));
    }
  }
  
  async function onSubmit(e){
    e.preventDefault();
    async function storePdf(pdf){
      return new Promise((resolve, reject) =>{
        const storage = getStorage();
        const filename = `${pdf.name}-${uuidv4()}`;
        const storageRef= ref(storage, filename);
        
        const uploadTask = uploadBytesResumable(storageRef, pdf);
        uploadTask.on(
          'state_changed',
          (snapshot) =>{
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress + "%");
            switch(snapshot.state){
              case 'paused': 
                console.log("Paused");
                break;
              case 'running':
                console.log("running");
                break;
            }
          },
          (error) => {
            reject(error);
          },
          ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>{
              resolve(downloadURL);
            });
          }
        );
      });
    }
    let pdfUrl = data.pdfUrl;
    if(pdfUpload){
      pdfUrl = await Promise.all(
        [...pdfFormData.pdfFile].map((pdf) => storePdf(pdf))
      ).catch((err) => {
        console.log(err);
        return;
      });
    }
    
    const docRef = doc(db, "weeklynews", id);
    try {
      await runTransaction(db, async (transaction) =>{
        const newsDoc = await transaction.get(docRef);
        if(!newsDoc.exists()){
          throw Error("Document does not exist");
        }
        transaction.update(docRef, {
          pdfTitle: pdfFormData.pdfTitle,
          pdfUrl: pdfUrl,
          timestamp : serverTimestamp(),
        });
      })
    } catch (error) {
      console.log(error);
    }
    
    window.location.reload();
  }
  
  return (
        <div className="flex-col justify-center items-center text-center w-full border-b
                        border-slate-300 cursor-pointer py-2
        ">
            <div className="grid grid-cols-6 p-2 items-center">
              <h4 className="font-semibold text-xs md:text-base lg:text-lg col-span-2 sm:col-span-1">{data.pdfTitle}</h4>
              <span className="col-span-3 sm:col-span-4"></span>
              <button className="hover:bg-[#395F89] border border-[#395F89] p-1 md:p-2 text-xs md:text-base"
                  onClick={()=>{setDisplayOn(!displayOn)}}>
                  주보보기
              </button>
              <div className="col-span-6 items-center">
                {admin && <div className="flex justify-center mr-4 space-x-2 bg-slate-100 py-2 mt-2">
                    <button onClick={() => {setOnPdfEdit(!onPdfEdit)}}
                            className="h-8 px-5 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out hidden">편집</button>
                    <button onClick={() => {onDelete()}}
                            className="h-8 px-5 bg-red-400 hover:bg-red-500 active:bg-red-700 rounded-sm transition duration-150 ease-in-out">삭제</button>
                  </div>}
                  
              </div>
            </div>
            {onPdfEdit && (
                <div className="mt-10">
                  <form onSubmit={onSubmit}>
                      <div className="">
                          <label htmlFor="pdfTitle" className="text-lg">제목: </label>
                          <input type="text" id="pdfTitle" value={pdfFormData.pdfTitle}
                                className="bg-slate-50 p-1 px-2 rounded-sm text-base"
                                onChange={(e)=>{onChange(e)}}        
                          />
                      </div>
                      <label htmlFor="pfdFile" className="text-lg">주보 이미지(앞/뒤) 파일을 올려주세요. </label>
                      <div className="space-x-4">
                        <input type="file" id="pdfFile"
                              className="bg-slate-50 p-1 px-2 rounded-sm text-base"
                              onChange={(e)=>{onChange(e)}}        
                        />
                        <button type="submit"
                                className="text-base h-8 px-5 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out my-3">
                          저장하기
                        </button>
                      </div>
                  </form>
                </div>)
            }
            {/* pdf container */}
            {displayOn && 
              <>
                <img
                  src={data.pdfUrl[0]}
                  frameBorder="0"
                  scrolling="yes"
                  width="100%"
                  alt='news1'
                  className="transition-all duration-200 ease-in-out 
                    animate-[dropmenu_1s_ease-in-out_1] border border-slate-300 rounded-md
                    shadow-md p-1 h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] mb-10"
                ></img>
                <img
                  src={data.pdfUrl[1]}
                  frameBorder="0"
                  scrolling="yes"
                  width="100%"
                  alt='news2'
                  className="transition-all duration-200 ease-in-out 
                    animate-[dropmenu_1s_ease-in-out_1] border border-slate-300 rounded-md
                    shadow-md p-1 h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] mb-10"
                ></img>
              </>
              
              }
              {displayOn && 
                  <div className="bg-slate-300 cursor-pointer font-bold text-2xl px-1 pt-1 shadow-md animate-pulse transition-transform border border-slate-200
                          duration-150 ease-in-out rounded-md w-10 mx-auto my-2"
                          onClick={()=>{setDisplayOn(false)}}
                  >^</div>
              }
        </div>
  )
}
