import React from 'react'
import { Link } from 'react-router-dom'

export default function VideoWorshipList({id, data}) {
  console.log(data)
  return (
    <>
      <Link className="w-full" to={`/video/worship/${id}`}>
          <div className="grid grid-cols-5 p-3 md:p-4 lg:p-6 border-b-2 border-slate-200 items-center
                          cursor-pointer hover:bg-slate-100 transition-all duration-150 ease-in-out 
          ">
              <span className="font-medium text-base md:text-lg col-span-5 md:text-left">{data.videoTitle}</span>
          </div>
      </Link>
    </>
  )
}
