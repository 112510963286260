import React from 'react'

export default function SliderImg({imgURL, id, onDelete, moveLeft, moveRight}) {
  return (
    <div className="bg-slate-200 p-4 m-5">
        <div className="flex justify-between mb-3 text-lg items-center">
            <h4 className="font-semibold text-xl">{id}</h4>
            <div className="space-x-4">
            {moveLeft && (
                <button onClick={()=>moveLeft(id)} 
                        className="border border-slate-300 bg-white py-0.5 px-2 hover:bg-slate-300 active:bg-slate-500 transition duration-150 ease-in-out rounded-md"> 
                        &lt; 
                </button>
            )}
            {moveRight && (
                <button onClick={()=>moveRight(id)} 
                        className="border border-slate-300 bg-white py-0.5 px-2 hover:bg-slate-300 active:bg-slate-500 transition duration-150 ease-in-out rounded-md"> 
                        &gt; 
                </button>
            )}
            </div>
        </div>
        <img src={imgURL}
            alt="" className="w-[360px] h-[240px] mb-4 hover:scale-[1.5] transition duration-150 ease-in-out"
        />
        <div className="flex justify-evenly space-x-4">
            <button 
                onClick={()=>onDelete(id)}
                className="bg-red-400 hover:bg-red-500 active:bg-red-700 w-full py-2 rounded-sm transition duration-150 ease-in-out">
                Remove
            </button>
        </div>
    </div>
  )
}
