import React from 'react'
import { getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import { useNavigate } from 'react-router'
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

export default function Login() {

  const navigate = useNavigate();
  async function googleLogin(){
    try{
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      console.log(user);

      const docRef = doc(db, "admin", user.uid);
      const docSnap = await getDoc(docRef);

      if(!docSnap.exists()){
        throw Error("");
      }
      navigate("/admin/home");
    }catch(error){
      toast.error("관리자 계정이 아닙니다. 홈으로 이동합니다.");
      console.log(error);
      // setTimeout(()=>{
      //   navigate("/")
      // }, 2000)
    }
  }

  return (
    <div className="h-screen flex justify-center items-center">
      {/* container */}
        <div className="bg-slate-400 border border-slate-400 shadow-md text-center
                        flex-col justify-center items-center
        ">
            <h2 className='font-bold text-2xl py-5 m-5 bg-slate-50 border border-slate-200 mt-10'>뉴욕 그레이스교회 로그인</h2>
            <button className="w-[500px] bg-red-500 text-white mx-5 py-3 my-3 mb-10 font-medium uppercase rounded
                    shadow-md hover:bg-red-600 transition duration-150 ease-in-out hover:shadow-lg active:bg-red-700
            " type="submit" onClick={googleLogin}>Google Login</button>
        </div>
    </div>
  )

}
