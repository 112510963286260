import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function Announcement({data, id}) {
 const location = useLocation();
 function getLink(){
    if(location.pathname === '/admin/news') return `/admin/announcementdetail/${id}`; //if admin, then route to admin view page
    return `/news/announcement/${id}`;
 }
  return (
    <>
      <Link className="w-full" to= {getLink()}>
          <div className="grid grid-cols-6 py-3 lg:py-6 border-b-2 border-slate-200 items-center
                          cursor-pointer hover:bg-slate-100 transition-all duration-150 ease-in-out
                          text-[14px] md:text-[16px] text-center
          ">
              <span className="col-span-3 text-left ml-2">{data.title}</span>
              <span className="col-span-1"></span>              
              <span className="col-span-2">{data.timestamp.toDate().toString().slice(4,15).replaceAll(" ","-")}</span>
          </div>
      </Link>
    </>
  )
}
