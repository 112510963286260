import React,{useEffect, useState} from 'react'
import Title from '../../components/Title';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';

export default function Location() {
  const pos = [40.76841, -73.73679];
  
  return (
    <div className="">
      <Title titleData={"Location / 위치"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
      </Title>
      <div className="mb-[10px]">
        {/* Google map container */}
        <div className="max-w-5xl mx-auto my-10">
          <h4 className="font-bold text-lg md:text-xl lg:text-2xl text-left py-3 lg:py-6 ml-2 lg:ml-4">Grace New York Baptist Church</h4>
          <div className="h-[300px] md:h-[400px] w-full">
            <MapContainer className="z-99" center={pos} zoom={40} scrollWheelZoom={false} style={{height:"100%", width:"100%"}}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={pos}>
                <Popup>
                   그레이스 뉴욕 교회 
                   <br/>
                   Grace NewYork Korean Baptist Church
                   <br />
                   45-14 251 St #FL G, 11362 
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="flex space-x-4 md:space-x-7 lg:space-x-10 items-center py-3 lg:py-10 ml-2 lg:ml-4">
            <h2 className="font-semibold text-sm md:text-base lg:text-xl">주소 및 연락처</h2>
            <div className="text-xs md:text-base lg:text-lg">
              <p className="uppercase">Grace NewYork Korean Baptist Church <br></br>45-14 251 St #FL G, 11362</p>
              <p>+1 (347) 732-4627</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
