import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import {db} from "../../firebase";
import { collection, getDocs, query, where} from "firebase/firestore";

export default function Aboutus() {

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    setTitle("About us / 교회 소개")
    fetchInfoData();
  },[])

  const [pageData, setPageData] = useState({
    mainImgUrl : {},
    intro: "",
    staffs: {},
  });

  async function fetchInfoData(){
    const infoRef = collection(db, "info")
    const q = query(infoRef,where("userRef", "==", "9Bp5T61uU3b2vLiXBjiV16IDVC02"));
    const querySnap = await getDocs(q);
    const data = querySnap.docs[0].data();
    setPageData(data);
    setLoading(false);
  }

  // console.log(pageData.staffs[0].staffImgUrl[0])

  return (
    <>
    {/* https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80 */}
    
      <Title titleData={title} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80">
      </Title>
      <div className='max-w-5xl mx-auto'>
        {/* pastor greeting and intro*/}
        <div className="mb-6 md:px-0">
          <div className='grid grid-cols-8 mt-7 lg:mt-10 lg:space-x-5'>  
            <div  className="col-span-8 md:col-span-4 flex flex-col items-center text-center">
              <img className="w-[280px] h-[310px] md:h-[400px] md:w-[340px] lg:h-[480px] lg:w-[400px] mx-auto" src={`${pageData.mainImgUrl[0]}`} alt="" />
            </div>
            <div className="col-span-8 md:col-span-4 px-2 lg:px-0 mt-10 md:mt-0">
              <p className="pb-5 text-base md:text-lg lg:text-xl">
                <h3 className='font-medium text-[20px] md:text-[30px] xl:text-[40px] mb-10'>인사말</h3>
                {!loading && (
                  <div dangerouslySetInnerHTML={{__html:pageData.intro}}></div>
                )}
              </p>
            </div>
          </div>  
        </div>
        {/* <div className="px-2 lg:px-0">
          <p className="pb-5 text-base md:text-lg lg:text-xl mt-10">
            <h3 className='font-medium text-[20px] md:text-[30px] xl:text-[40px] mb-10'>인사말</h3>
            {!loading && (
              <div dangerouslySetInnerHTML={{__html:pageData.intro}}></div>
            )}
          </p>
        </div> */}
        {/* end -- pastor greeting and intro section */}
        {/* section title */}
        
        <h3 className="text-xl md:text-3xl lg:text-5xl font-medium mb-6 lg:mb-10 text-center w-full py-2 md:py-3
        border-b-2 border-t-2 md:border-b-4 md:border-t-4 border-[#395F89]">섬기는 사람들</h3>
        {/* contents container */}
        <div className="grid grid-cols-2 mb-20">
          {/* left */}
          <div className="col-span-1 mx-auto">
            {/* content */}
            {!loading && pageData.staffs.map((staff, index)=>{
              if(index % 2 !== 0) return <></>;
              return (
                <div className="items-center p-5">
                  <img className="h-[150px] w-[150px] lg:w-[250px] lg:h-[250px] rounded-full shadow-md border-2 border-blue-50" 
                      src={staff.staffImgUrl[0]} alt="" />
                  <div className="mt-2">
                    <div className="flex space-x-2 items-baseline">
                      <h5 className="text-lg">{staff.staffName}</h5>
                      <small className="">{staff.staffRole}</small>
                    </div>
                    <small>{staff.staffEmail}</small>
                  </div>
                </div>
              )
            })}
          </div>
          {/* right */}
          <div className="col-span-1 mx-auto">
            {!loading && pageData.staffs.map((staff, index)=>{
                if(index % 2 !== 1) return <></>;
                return (
                  <div className="items-center p-5">
                    <img className="h-[150px] w-[150px] lg:w-[250px] lg:h-[250px] rounded-full shadow-md border-2 border-blue-50" 
                        src={staff.staffImgUrl[0]} alt="" />
                    <div className="mt-2">
                      <div className="flex space-x-2 items-baseline">
                        <h5 className="text-lg">{staff.staffName}</h5>
                        <small className="">{staff.staffRole}</small>
                      </div>
                      <small>{staff.staffEmail}</small>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
    
  )
}
