import React, { useState } from 'react';
import ReactQuill,{ Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import { getDownloadURL, getStorage, uploadString, ref } from 'firebase/storage';
import {addDoc, collection, doc, serverTimestamp, runTransaction, deleteDoc} from "firebase/firestore";
import {v4 as uuidv4} from "uuid";
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';


export default function TextEditor({textInput, contentTitle, id, collectionName, completeReq, bar}) {
  const auth = getAuth();
  const [editorInput, setEditorInput] = useState(textInput);
  const [title, setTitle] = useState(contentTitle);
  const navigate = useNavigate();
  let titleImageURL = "";
  //https://www.npmjs.com/package/react-quill
  //https://github.com/zenoamaro/react-quill
  //https://firebase.google.com/docs/storage/web/upload-files
  //https://github.com/kensnyder/quill-image-resize-module/issues/43
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      [{'size':['small', 'normal', 'large', 'huge']}],
      ['color', 'background'],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'align': ['right', 'center', 'justify']}],
      ['link', 'image', 'video'],
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
        handleStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
            // other camelCase styles for size display
        }
    }
  }

  // BEGIN allow image alignment styles
  const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
  ];
  //more code for alignment styling
  const BaseImageFormat = Quill.import('formats/image');
  class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
      return ImageFormatAttributesList.reduce(function(formats, attribute) {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute);
        }
        return formats;
      }, {});
    }
    format(name, value) {
      if (ImageFormatAttributesList.indexOf(name) > -1) {
        if (value) {
          this.domNode.setAttribute(name, value);
        } else {
          this.domNode.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }
  Quill.register(ImageFormat, true);
  Quill.register('modules/imageResize', ImageResize);
  window.Quill = Quill;

  async function storeImages(data){
    return new Promise((resolve, reject) =>{
      toast.info("이미지를 업로드하는 중입니다..")

      const storage = getStorage();
      const filename = `${collectionName}-${uuidv4()}`;
      const storageRef= ref(storage, filename);
      uploadString(storageRef, data, 'data_url').then(
        (snapshot) =>{
            getDownloadURL(snapshot.ref)
            .then((url) => {
                resolve(url)
            })  
        },
        (error) => {
            reject(error);
        },
      );
    });
  }

  async function parseInput(findImgInd){
    let tempInput = editorInput, photoInd = 0;
    let imgMark = `<&photo#${photoInd}&>`;
    let imgArr = [], imgWidths = [], styleTags = [];
    //case1 : no change -> no both
    //case2 : width change -> both
    //case3 : style change -> style no width
    //case4 : both change -> both
    while(findImgInd !== -1){
        //extract the data without "
        let s = findImgInd + 10, e = 0;
        for(let i=s;i<tempInput.length;i++){
          if(tempInput.at(i) === '"'){
                  e = i;
                  break;
              }
        }
        let imgData = tempInput.substring(s,e);
        //get style and width
        let styleTag = "", width = "";
        let findStyleAttr = tempInput.indexOf('style="', e+1);
        let findWidthAttr = tempInput.indexOf('width="', e+1);
        let endImgTag = tempInput.indexOf(">", e);
        //if style and width attr exist and belong to the imgData
        if(findStyleAttr !== -1 && (endImgTag > findStyleAttr)){
          let styleStart = tempInput.indexOf('"', findStyleAttr)+1; //without "
          let styleEnd = tempInput.indexOf('"', styleStart);
          styleTag = tempInput.substring(styleStart, styleEnd);
        }
        //get width
        if(findWidthAttr !== -1 && (endImgTag > findWidthAttr)){
          let wValueStart = tempInput.indexOf('"', findWidthAttr)+1; //without "
          let wValueEnd = tempInput.indexOf('"', wValueStart);
          width = tempInput.substring(wValueStart, wValueEnd);
        }
        //push the img to img array
        styleTags.push(styleTag);
        imgWidths.push(width);
        imgArr.push(imgData);
        //find front, back of the img position
        let front = tempInput.substring(0, findImgInd);
        let back = tempInput.substring(tempInput.indexOf(">", e)+1);
        //mark and combine front and back
        front = front.concat(imgMark);
        tempInput = front.concat(back);
        
        //update
        photoInd++;
        imgMark = `<&photo#${photoInd}&>`;
        findImgInd = tempInput.indexOf('<img src="data:image/');
    }
    let imgUrls = await Promise.all(
      [...imgArr].map((data) => {
        return storeImages(data);
      })
    ).catch((error)=>{
      console.log(error);
      return;
    });
    
    if(collectionName === "photogallery") {
      titleImageURL = imgUrls[0];
    }
    return convertMarkToImgTag(tempInput, imgUrls, styleTags, imgWidths);
  }


  function convertMarkToImgTag(tempInput, imgUrls, styleTags, imgWidths){
    for(let i=0;i<imgUrls.length;i++){
      let imgMark = `<&photo#${i}&>`;
      let startFrom = tempInput.indexOf(imgMark)
      let imgTag = `<img src="${imgUrls[i]}" style="${styleTags[i]}" width="${imgWidths[i]}">`
      let front = tempInput.substring(0, startFrom);
      let back = tempInput.substring(startFrom + imgMark.length);
      front = front.concat(imgTag);
      tempInput = front.concat(back);
    }
    return tempInput;
  }

  async function onSave(){
    if(title === ""){
      window.alert("제목을 적어주세요.");
      return;
    }
    toast.info("저장중입니다. 잠시만 기다려주세요.")
    bar(100);
    //scan user input to detect image file
    //then extract the image file data
    //upload it to the storage
    //getdownload url
    //then replace src data value with the url
    //consider the case that the user enter image file after or between text
    //optimize it with the idea that uploading single or multiple image files
    //when the user submitting the post form.
    //console.log(editorInput);
    //indexOf -> -1 if there is no matched string
    let findImgInd = editorInput.indexOf('<img src="data:image/');
    let tempInput = "";
    if(findImgInd !== -1){
      console.log("parse");
      tempInput = await parseInput(findImgInd);
    }
    else{
      tempInput = editorInput;
    }
    // console.log(tempInput);
    // setEditorInput(tempInput);
    // store the post with text content and imgurls
    // const content = {
    //   title: title,
    //   textContent: tempInput,
    //   timestamp : serverTimestamp(),
    //   userRef : auth.currentUser.uid
    // }
    const ref = collection(db, collectionName);
    if(id){ //edit
      const titleImg = getFirstImg(tempInput);
      console.log(titleImg)
      const docRef = doc(ref, id)
      try {
        await runTransaction(db, async (transaction) =>{
          const post = await transaction.get(docRef);
          if(!post.exists()){
            throw Error("Document does not exist");
          }
          transaction.update(docRef, {
            title: title,
            textContent: tempInput,
            timestamp : serverTimestamp(),
            userRef : auth.currentUser.uid,
            titleImageURL : titleImg
          });
          
        })
      } catch (error) {
        console.log(error);
      }
    }
    else{
      await addDoc(ref, {
        title: title,
        textContent: tempInput,
        timestamp : serverTimestamp(),
        userRef : auth.currentUser.uid,
        titleImageURL : titleImageURL
      });
    }
    console.log(tempInput);
    console.log("done");
    completeReq();
    // window.location.reload();
  }
  function getFirstImg(tempInput) {
    const firstImgInd = tempInput.indexOf("<img src=");
    const endFirstImgInd = tempInput.indexOf('"', firstImgInd + 11);
    return tempInput.substring(firstImgInd + 10, endFirstImgInd);
  }
  function onChange(e){
    if(e.target.id === "title"){
      setTitle(e.target.value);
    }
  }

  async function removePost(){
    if(window.confirm("이 게시물을 삭제하시겠습니까? 삭제시 복구가 불가능합니다.")){
      try {
        await deleteDoc(doc(db, collectionName, id));
        navigate("/admin/news");
        toast.success(contentTitle + " 게시물 삭제를 완료했습니다.")
      } catch (error) {
        toast.error("삭제 중 오류가 발생했습니다. 관리자에게 문의해주세요.")
      }
    }
  }

  function handleUserInputChange(newUserValue) {
    setEditorInput(newUserValue);
    console.log(newUserValue);
  }

  return(
    <>  
        <div className="max-w-5xl mx-auto mt-10">
            <div className="bg-slate-300 p-3 flex justify-between items-center space-x-2">
                <div className="">
                    <label htmlFor="title" className="text-lg">제목: </label>
                    <input value={title} type="text" id="title" className="bg-slate-50 p-1 px-2 rounded-sm"
                           onChange={(e)=>{onChange(e)}}        
                    />
                </div>
                <div className="space-x-2">
                  <button onClick={()=>{onSave()}} 
                          className="h-8 px-5 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded-sm transition duration-150 ease-in-out my-3">
                      save
                  </button>
                  {id && (<button onClick={()=>{removePost()}}
                          className="h-8 px-5 bg-red-400 hover:bg-red-500 active:bg-red-700 rounded-sm transition duration-150 ease-in-out">
                            삭제
                  </button>)}                
                </div>   
            </div>
            <ReactQuill 
                    theme="snow" onChange={handleUserInputChange} value={editorInput}
                    modules={modules} className='h-[80vh] mb-10' ImageResize={{modules:["Resize"]}}
                    
            />
        </div>
    </>
)
    
}


