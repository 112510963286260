import React from 'react'
import Title from '../../components/Title'
import vision from '../../asset/vision.jpg'

export default function Vision() {

  return (
    <>
      <Title titleData={"Vision / 목회철학"} brightPercent={70} pos={"center"}
             backImg="https://images.unsplash.com/photo-1650053816883-c6344709fea7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80"></Title>
      <div className="max-w-5xl mx-auto mb-20 pt-[30px]">
        {/* vision header */}
        <div className="p-10 w-full bg-[#395F89] my-10 flex-col justify-center items-center text-center text-white">
            <p className="text-xl md:text-3xl font-bold mb-8">
              그레이스 뉴욕교회는 <br className='sm:hidden'></br> “오직 예수 그리스도”를 전하는 교회입니다. </p>
            <p className='border-b-[1px] border-white w-24 mt-4 mx-auto shadow-md pb-1'>골로새서 1:28</p>
            <p className="mt-8 text-semibold text-base md:text-lg text-center">
              그레이스 뉴욕교회는 “한 영혼”이 그리스도 안에서 예수님을 만나고, 예수님을 배우고, 예수님을 섬기고, 예수님을 따르는
              “온전한 사람”으로 세우기 위하여 힘쓰고 애쓰는 공동체입니다. 
            </p>
        </div>
        {/* main container */}
        <div className="">
          <img alt="" src={vision} 
              className=""/>
        </div>
        {/* main vision container end */}
      </div>
    </>
  )
}
